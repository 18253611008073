const initialState = {
  all: [],
  current: null,
  filtered: null,
  filter: null,
  error: null,
  loading: true,
  bypassIo: false,
  open: false,
};

const exclusion_rules = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_EXCLUSION_RULE":
      return { ...state, all: [...state.all, action.payload] };
    case "DELETE_EXCLUSION_RULE":
      return {
        ...state,
        all: state.all.filter((exclusion_rule) => exclusion_rule.id !== action.payload)
      };
    case "UPDATE_EXCLUSION_RULE":
      return {
        ...state,
        all: state.all.map((exclusion_rule) =>
          exclusion_rule.language_id !== action.payload.language_id
            ? exclusion_rule
            : action.payload
        )
      };
    case "GET_EXCLUSION_RULES":
      return { ...state, all: action.payload };
    case "GET_EXCLUSION_RULE_LANGUAGES":
      return { ...state, exclusion_rule_languages: action.payload };
    case "GET_EXCLUSION_RULE_DETAILS":
      return { ...state, logged_exclusion_rule: action.payload };
    case "CURRENT_EXCLUSION_RULE":
      return { ...state, current: action.payload };
    case "CLEAR_CURRENT_EXCLUSION_RULE":
      return { ...state, current: null };
    case "SET_BYPASS_IO_EXCLUSION_RULES":
      return { ...state, bypassIo: action.payload };
    case "SET_OPEN_EXCLUSION_RULE":
      return { ...state, open: action.payload };

    case "ERROR_EXCLUSION_RULES":
      let tmpError = action.payload;
      try {
        tmpError = JSON.parse(tmpError.response.data.message);
        tmpError.list = tmpError.list
          ? "<ul>" +
            tmpError.list.map((error) => "<li>" + error + "</li>").join("") +
            "</ul>"
          : "";
      } catch (err) {
        tmpError = {
          msg: tmpError.response
            ? tmpError.response.data.message
            : tmpError.toString(),
          list: ""
        };
      }

      return { ...state, error: tmpError };

    case "CLEAR_ERROR_EXCLUSION_RULES":
      return { ...state, error: null };
    case "LOADING_EXCLUSION_RULES":
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default exclusion_rules;
