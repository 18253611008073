import React, { useEffect, useState, useContext, Fragment } from "react";
import { connect } from "react-redux";
import Calendar from "react-calendar";

import {
  getLanguages,
  getSourceTypes,
  getSourceStatuses,
  getJurisdictions,
  getAlertSubaccounts
} from "../actions/related";

import { AuthContext } from "../shared/context/auth-context";

import ErrorSnack from "../shared/components/UIElements/ErrorSnack";
import EnhancedTable from "../shared/components/UIElements/EnhancedTable";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  Icon,
  Breadcrumbs,
  Link,
  TextField,
  Grid,
  Dialog
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useConfirm } from "material-ui-confirm";

import ProgressBar from "../shared/components/UIElements/ProgressBar";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import { getSources } from "../actions/sources";
import AlertLog from "./AlertLog";
import { Helmet } from "react-helmet";

const AlertLogs = ({
  error,
  sources,
  alert_subaccounts,
  getSources,
  getAlertSubaccounts
}) => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [logs, setLogs] = useState([]);
  const [issuesLogs, setIssuesLogs] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedArticle, setSelectedArticle] = React.useState({});

  useEffect(() => {
    if ((!sources || sources.length === 0) && !error) getSources();
    if (!alert_subaccounts || alert_subaccounts.length === 0)
      getAlertSubaccounts();

    const fetchData = async () => {
      setLoading(true);
      const result = await axios(
        process.env.REACT_APP_BACKEND_URL +
          "/alerts/log/" +
          date.toISOString().split("T")[0]
      );
      setLoading(false);
      setLogs(result.data.correct);
      setIssuesLogs(result.data.issues);
    };

    fetchData();
  }, [date]);

  const columns = [
    {
      id: "publisher",
      numeric: false,
      disablePadding: false,
      label: "Publisher"
    },
    {
      id: "original_header",
      numeric: false,
      disablePadding: false,
      label: "Header"
    },
    {
      id: "translated_header",
      numeric: false,
      disablePadding: false,
      label: "Translated Header"
    },
    {
      id: "original_url",
      numeric: false,
      disablePadding: false,
      label: "URL",
      noSelect: true,
      transform: function (row) {
        return (
          <a href={row.original_url} target="_blank">
            {row.original_url.slice(0, 30) + "..."}
          </a>
        );
      }
    },
    {
      id: "extracted_data",
      numeric: false,
      disablePadding: false,
      label: "Extract",
      noSelect: true,
      transform: function (row) {
        if (row.article_content && row.article_content.content)
          return <a onClick={(e) => handleClickOpen(row)}>EXTRACTED</a>;
      }
    },
    {
      id: "source_id",
      numeric: false,
      disablePadding: false,
      label: "Source",
      noSelect: true,
      transform: function (row) {
        if (!row.source_id) return "N/A";
        else return sources.find((s) => s.id === row.source_id).name;
      }
    }
  ];

  const handleClickOpen = (article) => {
    setSelectedArticle(article);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedArticle({});
  };

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Logs - ASPU</title>
      </Helmet>
      {loading && <ProgressBar />}
      <ErrorSnack error={error} />
      <AlertLog
        selectedArticle={selectedArticle}
        open={open || false}
        onClose={handleClose}
      />
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="xl">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" href="/">
              Configuration
            </Link>
            <Link color="textPrimary" href="/alert-logs" aria-current="page">
              Alet Logs
            </Link>
          </Breadcrumbs>
          <Grid container spacing={0}>
            <Grid item xs={12} md={3}>
              <Calendar onChange={setDate} value={date} />
            </Grid>
            <Grid item xs={12} md={9}>
              <Paper style={{ padding: "10px" }}>
                <strong>Date:</strong> {date.toISOString().split("T")[0]}
                <br />
                <strong>Number of news collected:</strong>{" "}
                {logs.length + issuesLogs.length}
                <br />
                <strong>Number of news with associated source:</strong>{" "}
                {logs.length}
                <br />
                <strong>Number of news without source:</strong>{" "}
                {issuesLogs.length}
                <br />
              </Paper>
            </Grid>
          </Grid>
          <br />
          <br />
          {issuesLogs.length !== 0 && sources.length !== 0 && (
            <Fragment>
              <EnhancedTable
                rows={issuesLogs}
                columns={columns}
                options={{
                  title:
                    issuesLogs.length +
                    " news with issues for " +
                    date.toISOString().split("T")[0],
                  multiple_select: false
                }}
              ></EnhancedTable>
            </Fragment>
          )}
          {logs.length !== 0 && sources.length !== 0 && (
            <Fragment>
              <EnhancedTable
                rows={logs}
                columns={columns}
                options={{
                  title: "Correct logs for " + date.toISOString().split("T")[0],
                  multiple_select: false
                }}
              ></EnhancedTable>
            </Fragment>
          )}
        </Container>
      </React.Fragment>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableHead: {
    backgroundColor: "#eee"
  },
  progress: {
    position: "absolute",
    top: "60px",
    right: "20px"
  },
  modalPaper: {
    width: "10%"
  }
});

const mapStateToProps = (state) => ({
  sources: state.sources.all,
  alert_subaccounts: state.related.alert_subaccounts
});

const mapDispatchToProps = (dispatch) => ({
  getSources: () => dispatch(getSources()),
  getAlertSubaccounts: () => dispatch(getAlertSubaccounts())
});
export default connect(mapStateToProps, mapDispatchToProps)(AlertLogs);
