import React, { useEffect, useState, useContext, Fragment } from "react";
import { connect } from "react-redux";

import {
  deleteUser,
  getUsers,
  clearErrorUsers,
  setCurrentUser,
  clearCurrentUser,
  setBypassIoUsers
} from "../actions/users";

import subscribeTo from "../utils/subscribeTo";
import { AuthContext } from "../shared/context/auth-context";
import UserForm from "./UserForm";
import AddUserBtn from "./AddUserBtn";
import ErrorSnack from "../shared/components/UIElements/ErrorSnack";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Icon, Breadcrumbs, Link } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useConfirm } from "material-ui-confirm";
import { Helmet } from "react-helmet";

const Users = ({
  users,
  loading,
  error,
  current,
  bypassIo,
  clearErrorUsers,
  setCurrentUser,
  clearCurrentUser,
  setBypassIoUsers,
  deleteUser,
  getUsers,
  dispatch
}) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const confirm = useConfirm();

  useEffect(() => {
    if ((!users || users.length === 0) && !error) getUsers();
    if (!loading) {
      subscribeTo("usersChanged", (err, data) => {
        if (!bypassIo) getUsers();
        else {
          getUsers();
          setBypassIoUsers(false);
        }
      });
      return () => {
        subscribeTo("usersChanged", null);
      };
    }
  }, [loading, bypassIo]);

  const onClickDelete = (confirm, user) => {
    confirm({
      description: `Are you sure you want to delete the user "${user.email}"?`,
      title: "WARNING",
      cancellationText: "Cancel",
      confirmationText: "Confirm"
    }).then(() => deleteUser(user.id));
  };

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Users - ASPU</title>
      </Helmet>
      <ErrorSnack error={error} clearError={clearErrorUsers} />
      <React.Fragment>
        <CssBaseline />
        <UserForm current={current} clearCurrentUser={clearCurrentUser} />
        <Container maxWidth="xl">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" href="/">
              Configuration
            </Link>
            <Link color="textPrimary" href="/users" aria-current="page">
              Users
            </Link>
          </Breadcrumbs>

          {users.length !== 0 && (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>E-mail</TableCell>
                    <TableCell>Roles</TableCell>
                    <TableCell>Accepted</TableCell>
                    <TableCell>Rejected</TableCell>
                    <TableCell>Last Login</TableCell>

                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((row) => (
                    <TableRow
                      key={row.name}
                      style={{
                        ...(row.is_disabled && {
                          textDecoration: "line-through"
                        })
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>
                        {row.roles.map((role) => role.name).join(", ")}
                      </TableCell>
                      <TableCell>{row.accepted}</TableCell>
                      <TableCell>{row.rejected}</TableCell>
                      <TableCell>
                        {row.last_login &&
                          new Date(row.last_login).toUTCString()}
                      </TableCell>

                      <TableCell align="right">
                        {row.id && (
                          <Fragment>
                            <a onClick={() => setCurrentUser(row)}>
                              <Icon>edit</Icon>
                            </a>{" "}
                            <a onClick={(e) => onClickDelete(confirm, row)}>
                              <Icon color="secondary">delete</Icon>
                            </a>
                          </Fragment>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <AddUserBtn />
        </Container>
      </React.Fragment>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableHead: {
    backgroundColor: "#eee"
  },
  progress: {
    position: "absolute",
    top: "60px",
    right: "20px"
  },
  modalPaper: {
    width: "10%"
  }
});

const mapStateToProps = (state) => ({
  users: state.users.all,
  loading: state.users.loading,
  error: state.users.error,
  current: state.users.current,
  bypassIo: state.users.bypassIo
});

const mapDispatchToProps = (dispatch) => ({
  deleteUser: (id) => dispatch(deleteUser(id)),
  getUsers: () => dispatch(getUsers()),
  clearErrorUsers: () => dispatch(clearErrorUsers()),
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  clearCurrentUser: () => dispatch(clearCurrentUser()),
  setBypassIoUsers: (bypass) => dispatch(setBypassIoUsers(bypass))
});
export default connect(mapStateToProps, mapDispatchToProps)(Users);
