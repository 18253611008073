import React, { useEffect, useState, Fragment } from "react";
import { VictoryPie, VictoryTheme } from "victory";
import { Card, Grid, makeStyles } from "@material-ui/core";
import axios from "axios";
import ProgressBar from "../shared/components/UIElements/ProgressBar";

const useStyles = makeStyles({
  card: {
    padding: "10px"
  }
});

const Parents = () => {
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await axios(
        process.env.REACT_APP_BACKEND_URL + "/alerts/lastMonth"
      );
      let theParents = {};
      result.data.parents.forEach(
        (parent) => (theParents[parent.id] = { ...parent, children: [] })
      );
      result.data.childs.forEach((child) =>
        theParents[child.parent_article_id].children.push(child)
      );
      setNews(theParents);
      setLoading(false);
      console.log(Object.keys(theParents));
    };

    fetchData();
  }, []);

  const classes = useStyles();
  return (
    <Card>
      {loading && <ProgressBar />}
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={2}
        className={classes.card}
      >
        <Grid item xs={12}>
          <h1>Parents and childs</h1>
        </Grid>
        <Grid item xs={12}>
          {!loading &&
            Object.keys(news).map((article_id) => (
              <Fragment>
                <Card>
                  <h2>{news[article_id].original_header}</h2>
                  {news[article_id].children.map((article) => (
                    <h4>{article.original_header}</h4>
                  ))}
                </Card>
                <br />
                <br />
              </Fragment>
            ))}
        </Grid>
      </Grid>
    </Card>
  );
};

export default Parents;
