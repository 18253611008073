import React, { useEffect, useState } from "react";
import { VictoryPie, VictoryTheme } from "victory";
import { Card, Grid, makeStyles } from "@material-ui/core";
import axios from "axios";
import ProgressBar from "../shared/components/UIElements/ProgressBar";

const useStyles = makeStyles({
  card: {
    padding: "10px"
  }
});

const DuplicatePie = () => {
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState({ parents: [], childs: [] });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await axios(
        process.env.REACT_APP_BACKEND_URL + "/alerts/lastMonth"
      );
      
      setNews(result.data);
      setLoading(false);
      console.log(result.data);
    };

    fetchData();
  }, []);

  const classes = useStyles();
  return (
    <Card>
      {loading && <ProgressBar />}
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={2}
        className={classes.card}
      >
        <Grid item xs={12}>
          <h1>Last month statistics</h1>
        </Grid>
        <Grid item xs={6}>
          {!loading && news.parents.length && (
            <VictoryPie
              theme={VictoryTheme.material}
              padding={{ left: 80, right: 150 }}
              data={[
                {
                  x: "Parents\n" + news.parents.length,
                  y: news.parents.length,
                  fill: "orange"
                },
                {
                  x: "Childs\n" + news.childs.length,
                  y: news.childs.length,
                  fill: "tomato"
                }
              ]}
            />
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default DuplicatePie;
