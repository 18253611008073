import {
  Button,














  Checkbox, Dialog,





  DialogActions, DialogContent,
  DialogTitle,









  FormControl, Grid, Icon,












  IconButton, Input, InputAdornment,


  InputLabel,




  ListItemText, MenuItem, OutlinedInput,
  Select, TextField, useMediaQuery,
  useTheme
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getLanguages } from "../actions/related";
import {
  addUser,

  clearCurrentUser, clearErrorUsers, deleteUser,
  getUsers,

  setCurrentUser,



  setOpenUser, updateUser
} from "../actions/users";



const emptyUser = {
  name: "",
  email: "",
  password: "",
  roles: [],
  showPassword: false,
  languages: [{ name: "English", id: "en" }]
};

const UserForm = ({
  current,
  open,
  clearCurrentUser,
  updateUser,
  setOpenUser,
  addUser,
  loading,
  allLanguages,
  getLanguages
}) => {
  const classes = useStyles();
  const [user, setUser] = useState(emptyUser);

  const { name, email, password, languages, roles, showPassword } = user;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };
  const theRoles = [
    { id: 1, name: "Admin" },
    { id: 2, name: "Editor" },
    { id: 3, name: "Editor Plus" }
  ];

  useEffect(() => {
    if (!allLanguages || !allLanguages.length) getLanguages();
    if (current !== null) {
      setUser(current);
    } else {
      setUser(emptyUser);
    }
  }, [current, open]);

  function handleClose() {
    setOpenUser(false);
    clearCurrentUser();
  }
  function onSubmit(e) {
    e.preventDefault();

    try {
      if (!current) {
        addUser(user);
      } else {
        updateUser(user);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const clearAll = () => {
    setUser(emptyUser);
    clearCurrentUser();
  };

  const onChange = (e, newVal = null) => {
    let val;
    if (e === "languages") {
      setUser({ ...user, [e]: newVal });
    } else if (e.target.name === "roles") {
      val = e.target.value;
      val = val.map((v) => {
        if (Number.isInteger(v))
          return {
            id: v,
            name: theRoles.find((r) => r.id === v).name
          };
        else return v;
      });
      setUser({ ...user, [e.target.name]: val });
    } else {
      val = e.target.value;
      setUser({ ...user, [e.target.name]: val });
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />

      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        fullScreen={true}
        style={
          !useMediaQuery(useTheme().breakpoints.down("sm"))
            ? { width: "40%", height: "35%", margin: "auto" }
            : {}
        }
        open={current || open ? true : false}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        scroll="paper"
      >
        <DialogTitle
          id="max-width-dialog-title"
          color="primary"
          className={classes.formHeader}
        >
          {!current && open ? "New User" : current && !open ? "Edit User" : ""}
        </DialogTitle>

        <DialogContent dividers={true}>
          <form onSubmit={onSubmit}>
            <input type="submit" style={{ display: "none" }} />
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  name="name"
                  label="Name"
                  value={name}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  autocomplete="off"
                />
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  name="email"
                  label="E-mail"
                  value={email}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  autocomplete="off"
                />
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                <FormControl
                  className={clsx(classes.margin, classes.textField)}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                >
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <OutlinedInput
                    type={showPassword ? "text" : "password"}
                    margin="dense"
                    name="password"
                    id="password"
                    autocomplete="off"
                    onChange={onChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(e) =>
                            setUser({
                              ...user,
                              showPassword: !user.showPassword
                            })
                          }
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showPassword ? (
                            <Icon>visibility</Icon>
                          ) : (
                            <Icon>visibility_off</Icon>
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    className={classes.textField}
                    labelWidth={70}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel id="demo-mutiple-checkbox-label">
                    Roles
                  </InputLabel>
                  <Select
                    variant="outlined"
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    name="roles"
                    multiple
                    value={roles.map((r) => r.id)}
                    onChange={onChange}
                    input={<Input />}
                    renderValue={(selected) =>
                      selected
                        .map((s) => theRoles.find((r) => r.id === s).name)
                        .join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    {theRoles.map((role) => (
                      <MenuItem key={role.name} value={role.id}>
                        <Checkbox
                          checked={roles.find((r) => role.id === r.id)}
                        />
                        <ListItemText primary={role.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                {allLanguages && (
                  <Autocomplete
                    id="country-select-demo"
                    style={{ width: "100%" }}
                    options={allLanguages}
                    disableClearable={true}
                    classes={{
                      option: classes.option
                    }}
                    multiple
                    size="small"
                    onChange={(e, newVal) => onChange("languages", newVal)}
                    autoHighlight
                    defaultValue={languages}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option) => (
                      <React.Fragment>{option.name}</React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Languages"
                        variant="outlined"
                        margin="dense"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off",
                          form: {
                            autocomplete: "off"
                          }
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className={classes.formFooter}>
          <Button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Save
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            disabled={loading}
          >
            Cancel
          </Button>
        </DialogActions>
        <Fragment>
          <CssBaseline />
        </Fragment>
      </Dialog>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650
  },
  progress: {
    position: "absolute",
    top: "60px",
    right: "20px"
  },
  modalPaper: {
    width: "10%"
  },

  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  formHeader: {
    backgroundColor: "#002060",
    color: "white"
  },
  formFooter: {
    backgroundColor: "gainsboro",
    padding: "10px"
  }
}));

const mapStateToProps = (state) => ({
  users: state.users.all,
  loading: state.users.loading,
  error: state.users.error,
  current: state.users.current,
  open: state.users.open,
  allLanguages: state.related.languages
});

const mapDispatchToProps = (dispatch) => ({
  deleteUser: (id) => dispatch(deleteUser(id)),
  getUsers: () => dispatch(getUsers()),
  clearErrorUsers: () => dispatch(clearErrorUsers()),
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  setOpenUser: (should) => dispatch(setOpenUser(should)),
  clearCurrentUser: () => dispatch(clearCurrentUser()),
  addUser: (user) => dispatch(addUser(user)),
  updateUser: (user) => dispatch(updateUser(user)),
  getLanguages: () => dispatch(getLanguages())
});
export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
