import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import Icon from "@material-ui/core/Icon";
import { setOpenArticle } from "../actions/articles";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));
const AddArticleBtn = ({ dispatch }) => {
  const classes = useStyles();

  return (
    <div className="fixed-action-btn">
      <Fab
        onClick={() => dispatch(setOpenArticle(true))}
        color="primary"
        aria-label="Add"
        className={classes.fab}
      >
        <Icon>add</Icon>
      </Fab>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    articles: state.articles.articles,
    current: state.articles.current,
  };
};

export default connect(mapStateToProps)(AddArticleBtn);
