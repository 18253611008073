const initialState = {
  all: [],
  current: null,
  filtered: null,
  filter: null,
  error: null,
  loading: true,
  bypassIo: false,
  open: false
};

const delete_segments = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_DELETE_SEGMENT":
      
      return { ...state, all: action.payload };
    case "DELETE_DELETE_SEGMENT":
      console.log(state.all);
      return {
        ...state,
        all: state.all.map((delete_segment) => ({
          ...delete_segment,
          rules: delete_segment.rules.filter((ds) => ds !== action.payload)
        }))
      };
    case "UPDATE_DELETE_SEGMENT":
      return {
        ...state,
        all: state.all.map((delete_segment) =>
          delete_segment.language_id !== action.payload.language_id ? delete_segment : action.payload
        )
      };
    case "GET_DELETE_SEGMENTS":
      return { ...state, all: action.payload };
    case "GET_DELETE_SEGMENT_LANGUAGES":
      return { ...state, delete_segment_languages: action.payload };
    case "GET_DELETE_SEGMENT_DETAILS":
      return { ...state, logged_delete_segment: action.payload };
    case "CURRENT_DELETE_SEGMENT":
      return { ...state, current: action.payload };
    case "CLEAR_CURRENT_DELETE_SEGMENT":
      return { ...state, current: null };
    case "SET_BYPASS_IO_DELETE_SEGMENTS":
      return { ...state, bypassIo: action.payload };
    case "SET_OPEN_DELETE_SEGMENT":
      return { ...state, open: action.payload };

    case "ERROR_DELETE_SEGMENTS":
      let tmpError = action.payload;
      try {
        tmpError = JSON.parse(tmpError.response.data.message);
        tmpError.list = tmpError.list
          ? "<ul>" + tmpError.list.map((error) => "<li>" + error + "</li>").join("") + "</ul>"
          : "";
      } catch (err) {
        tmpError = {
          msg: tmpError.response ? tmpError.response.data.message : tmpError.toString(),
          list: ""
        };
      }

      return { ...state, error: tmpError };

    case "CLEAR_ERROR_DELETE_SEGMENTS":
      return { ...state, error: null };
    case "LOADING_DELETE_SEGMENTS":
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default delete_segments;
