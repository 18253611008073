import React, { useState, Fragment, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import { connect } from "react-redux";
import EnhancedTable from "../shared/components/UIElements/EnhancedTable";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { getLanguages } from "../actions/related";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  ExpansionPanel,
  Grid,
  Icon,
  Snackbar,
  IconButton,
  Paper,
  Dialog,
  Slide,
  TextField,
  Select
} from "@material-ui/core";
import moment from "moment";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import DateFnsUtils from "@date-io/moment"; // choose your lib
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";

import { setFilters } from "../actions/articles";

import Autocomplete from "@material-ui/lab/Autocomplete";

const rainbowColors = [
  "#FF0018",
  "#FFA52C",
  "#FFFF41",
  "#008018",
  "#0000F9",
  "#86007D",
  "#FF0018",
  "#FFA52C",
  "#FFFF41",
  "#008018",
  "#0000F9",
  "#86007D"
];

const inboxTabs = ["MOU", "CSO", "DOG", "GOV", "IHR", "OTHER"];

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(20),
    flexBasis: "13.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    verticalAlign: "middle",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}));

function InboxFilters({
  filter,
  setFilters,
  date,
  setDate,
  source_types,
  setPage,
  getLanguages,
  languages,
  jurisdictions,
  getJurisdictions,
  tier,
  setTier
}) {
  const classes = useStyles();

  const [filtersTmp, setFiltersTmp] = React.useState({
    header: ""
  });

  useEffect(() => {
    if (!languages || !languages.length) getLanguages();
    if (!jurisdictions || !jurisdictions.length) getJurisdictions();
    const delayDebounceFn = setTimeout(() => {
      setFilters(filtersTmp);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [filtersTmp.header]);

  useEffect(() => {
    setPage(1);
  }, [filter.filters.sou]);

  const tierTabs = [
    "#2c7bb6",
    "#abd9e9",
    "#ffffbf",
    "#fdae61",
    "#FF6600",
    "#CC3333",
    "#CC3300",
    "#FF0033"
  ];

  return (
    <Fragment>
      <Grid container spacing={0}>
        <Grid item xs={12} md={5}>
          {tierTabs.map((color, index) => (
            <Button
              key={index}
              variant="contained"
              onClick={(e) => setTier(index + 1)}
              style={{
                marginRight: "10px",
                fontWeight: "bold",
                marginTop: "10px",
                backgroundColor: color,
                ...(tier === index + 1 && {
                  border: "2px solid black"
                }),
                textTransform: "unset"
              }}
            >
              Tier {index + 1}
            </Button>
          ))}
          <Button
            key={0}
            variant="contained"
            onClick={(e) => setTier(0)}
            style={{
              marginRight: "10px",
              fontWeight: "bold",
              marginTop: "10px",
              backgroundColor: "whitesmoke",
              ...(tier === 0 && {
                border: "2px solid black"
              }),
              textTransform: "unset"
            }}
          >
            ALL
          </Button>
        </Grid>
        <Grid item xs={12} md={3}>
          {jurisdictions && jurisdictions.length > 0 && (
            <Autocomplete
              id="country-select-demo"
              style={{ width: "100%" }}
              options={jurisdictions
                .filter((j) => j.tlds)
                .map((j) => ({ id: j.tlds, name: j.name }))}
              disableClearable={false}
              classes={{
                option: classes.option
              }}
              multiple
              size="small"
              onChange={(e, newVal) =>
                setFilters({ ...filter.filters, tlds: newVal })
              }
              autoHighlight
              defaultValue={filter.tlds}
              getOptionLabel={(option) => option.name}
              renderOption={(option) => (
                <React.Fragment>{option.name}</React.Fragment>
              )}
              style={{ paddingRight: "5px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Estimated CONs"
                  variant="outlined"
                  margin="dense"
                  style={{ backgroundColor: "white" }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off",
                    form: {
                      autocomplete: "off"
                    }
                  }}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} md={2}>
          {languages && (
            <Autocomplete
              id="country-select-demo"
              style={{ width: "100%" }}
              options={languages}
              disableClearable={false}
              classes={{
                option: classes.option
              }}
              multiple
              size="small"
              onChange={(e, newVal) =>
                setFilters({ ...filter.filters, languages: newVal })
              }
              autoHighlight
              defaultValue={filter.languages}
              getOptionLabel={(option) => option.name}
              renderOption={(option) => (
                <React.Fragment>{option.name}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Languages"
                  variant="outlined"
                  margin="dense"
                  style={{ backgroundColor: "white" }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off",
                    form: {
                      autocomplete: "off"
                    }
                  }}
                />
              )}
            />
          )}
        </Grid>

        <Grid
          item
          xs={12}
          md={2}
          style={{ textAlign: "right", marginTop: "8px" }}
        >
          <TextField
            variant="outlined"
            size="small"
            style={{ width: "84%", backgroundColor: "white" }}
            value={filtersTmp.header}
            placeholder="Search..."
            onChange={(e) =>
              setFiltersTmp({ ...filter.filters, header: e.target.value })
            }
          />
          <Button
            variant="contained"
            style={{ width: "10%", marginLeft: "10px", marginTop: "2px" }}
            color="primary"
          >
            <Icon>search</Icon>
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
}
const mapStateToProps = (state) => ({
  loading: state.articles.loading,
  filter: state.articles.filter,
  languages: state.related.languages
});

const mapDispatchToProps = (dispatch) => ({
  setFilters: (filters) => dispatch(setFilters(filters, "_USI")),
  getLanguages: () => dispatch(getLanguages())
});
export default connect(mapStateToProps, mapDispatchToProps)(InboxFilters);
