import React from "react";

import "./MainHeader.css";

const MainHeader = (props) => {
  return (
    <header
      className={
        "main-header" + (process.env.REACT_APP_JC ? " main-header-jc" : "")
      }
    >
      {props.children}
    </header>
  );
};

export default MainHeader;
