import React, { Fragment, useEffect, useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  Paper,
  DialogActions,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  ListItemText,
  Input,
  MenuItem,
  OutlinedInput
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650
  },
  progress: {
    position: "absolute",
    top: "60px",
    right: "20px"
  },
  modalPaper: {
    width: "10%"
  },

  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  formHeader: {
    backgroundColor: "#002060",
    color: "white"
  },
  formFooter: {
    backgroundColor: "gainsboro",
    padding: "10px"
  }
}));

function TriggerForm(props) {
  const {
    newTrigger,
    setNewTrigger,
    type,
    tags,
    jurs,
    tiers,
    addTierTriggers
  } = props;

  const [newTags, setNewTags] = useState(null);
  const [iniTags, setIniTags] = useState([]);

  const [form, setForm] = useState({ tier: 2 });

  const classes = useStyles();

  useEffect(() => {
    setForm({ ...form, ...newTrigger });
  }, [newTrigger]);

  const handleClose = () => {
    setNewTrigger({});
  };

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const handleSave = () => {
    addTierTriggers(form.tier, form.text);
    handleClose();
  };
  let forms = {
    tier: (
      <Fragment>
        <Paper style={{ padding: "10px" }}>
          <Grid container>
            <Grid item xs={12} md={4}>
              <TextField
                select
                name="tier"
                value={form.tier}
                variant="outlined"
                margin="dense"
                onChange={onChange}
                fullWidth
              >
                {[2, 4, 5].map((tier) => (
                  <MenuItem key={tier} value={tier}>
                    <ListItemText primary={"Tier " + tier} />
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                style={{
                  marginTop: "7px",
                  marginLeft: "8px",
                  paddingRight: "8px",
                  height: "16px"
                }}
                name="text"
                label="Trigger - separate multiple with ;"
                onChange={onChange}
                value={form.text}
                className={classes.textField}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </Paper>
      </Fragment>
    )
  };
  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth={true}
      style={{ left: "20px" }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={newTrigger.type || false}
    >
      {forms[newTrigger.type]}

      {tags && tags.length > 0 && (
        <Paper style={{ padding: "10px" }}>
          <Autocomplete
            id="country-select-demo"
            style={{ width: "100%" }}
            options={tags}
            disableClearable={false}
            clearOnBlur={true}
            classes={{
              option: classes.option
            }}
            multiple
            defaultValue={iniTags}
            size="small"
            onChange={(e, newVal) => onChange(newVal)}
            getOptionLabel={(option) => option.tag}
            renderOption={(option) => (
              <React.Fragment>{option.tag}</React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tags"
                variant="outlined"
                autoFocus
                margin="dense"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off" // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Paper>
      )}
      <DialogActions>
        {" "}
        <Button onClick={handleClose} variant="contained" color="secondary">
          CLOSE
        </Button>{" "}
        <Button
          onClick={(e) => handleSave()}
          variant="contained"
          color="primary"
        >
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TriggerForm;
