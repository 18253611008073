import { useState, useCallback, useEffect } from 'react';
import jwt from 'jwt-decode'
import axios from 'axios';

let refreshTimer;

export const useAuth = (props) => {
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userId, setUserId] = useState(false);
  const [roles, setRoles] = useState([]);

  const login = useCallback((uid, token, expirationDate, roles) => {
    setToken(token);
    setUserId(uid);
    setRoles(roles);
    const tokenExpirationDate =
      expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 12);
    setTokenExpirationDate(tokenExpirationDate);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        userId: uid,
        token: token,
        expiration: tokenExpirationDate.toISOString(),
        roles,
      })
    );
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    localStorage.removeItem('userData');
    window.location='/'
  }, []);

  const refreshToken = useCallback(async () => {
    const responseData = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/users/refreshSession",
      { }
    );
    login(responseData.data.userId, responseData.data.token, new Date(jwt(responseData.data.token).exp*1000), responseData.data.roles);
  }, []);


  useEffect(() => {
    if (token){
      if (jwt(token).exp < new Date().getTime() / 1000)
       logout();  
    }
      
    if (token && jwt(token).exp) {
      
      const remainingTime = jwt(token).exp - new Date().getTime()/1000;
      
      clearTimeout(refreshTimer);
      refreshTimer = setTimeout(refreshToken, remainingTime*1000-60*1000);
    } else {
      clearTimeout(refreshTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('userData'));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.userId,
        storedData.token,
        new Date(storedData.expiration),
        storedData.roles
      );
    }
  }, [login]);

  return { token, login, logout, userId, roles };
};