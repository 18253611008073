import React, { Fragment, useEffect, useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  Paper,
  DialogActions,
  Button,
  TextField,
  DialogContent,
  Grid
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650
  },
  progress: {
    position: "absolute",
    top: "60px",
    right: "20px"
  },
  modalPaper: {
    width: "10%"
  },

  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  formHeader: {
    backgroundColor: "#002060",
    color: "white"
  },
  formFooter: {
    backgroundColor: "gainsboro",
    padding: "10px"
  }
}));

function SyntaxForm(props) {
  const {
    open,
    setOpen,
    sources,
    language_syntaxes,
    setSuccess,
    alert_subaccounts
  } = props;

  const [params, setParams] = useState({ mail: "", x: "", script: "" });
  const [iniTags, setIniTags] = useState([]);

  const classes = useStyles();

  const scrptStx = (url, syntax, x, mail, i, lang) => {
    if (lang === "zh") lang = "zh-CN";
    if (lang === "fil") lang = "tl";
    if (lang === "iw") lang = "he";
    if (lang === "gn") lang = "es";

    const gLangs = [
      "af",
      "ar",
      "hy",
      "be",
      "be",
      "ca",
      "zh",
      "hr",
      "cs",
      "da",
      "nl",
      "eo",
      "et",
      "tl",
      "fi",
      "fr",
      "de",
      "el",
      "he",
      "hi",
      "hu",
      "is",
      "id",
      "it",
      "ja",
      "ko",
      "lv",
      "no",
      "lt",
      "fa",
      "pl",
      "pt",
      "ru",
      "sr",
      "sk",
      "ro",
      "sl",
      "es",
      "sw",
      "sv",
      "th",
      "tr",
      "uk",
      "vi"
    ];

    if (gLangs.indexOf(lang) === -1) {
      lang = false;
    }
    //=CONCATENATE("setTimeout(()=>{",$'DO NOT CHANGE'.$A$1,$Instructions.$M$6,$'DO NOT CHANGE'.$F$1,$B2, " ", SUBSTITUTE($C2,CHAR(34),"“"),$'DO NOT CHANGE'.$C$1,IF(EXACT($E2,"ANY"),"en",$E2),$'DO NOT CHANGE'.$D$1,IF(EXACT($E2,"ANY"),"0","1"),$'DO NOT CHANGE'.$E$1,$Instructions.$M$4,$'DO NOT CHANGE'.$G$1,"},",$A2*1000,")")
    return `setTimeout(() => {
  fetch(
    "https://www.google.com/alerts/create?x=${x}",
    {
      credentials: "include",
      headers: {
        accept: "*/*",
        "accept-language": "en-US,en;q=0.9,es;q=0.8,pt;q=0.7,gl;q=0.6,da;q=0.5",
        "cache-control": "no-cache",
        "content-type": "application/x-www-form-urlencoded;charset=UTF-8",
        pragma: "no-cache",
        "sec-fetch-dest": "empty",
        "sec-fetch-mode": "cors",
        "sec-fetch-site": "same-origin"
      },
      referrer: "https://www.google.com/alerts",
      referrerPolicy: "no-referrer-when-downgrade",
      body:"params=%5Bnull%2C%5Bnull%2Cnull%2Cnull%2C%5Bnull%2C%22site%3A${encodeURIComponent(
        url
      )} ${syntax.replace(/\"/g, "“")}%22%2C%22com%22%2C%5Bnull%2C%22${
      lang ? lang : "en"
    }%22%2C%22US%22%5D%2Cnull%2Cnull%2Cnull%2C0%2C${
      lang ? 1 : 0
    }%5D%2Cnull%2C2%2C%5B%5Bnull%2C1%2C%22${mail}%22%2C%5Bnull%2Cnull%2C20%5D%2C2%2C%22en-US%22%2Cnull%2Cnull%2Cnull%2Cnull%2Cnull%2C%220%22%2Cnull%2Cnull%2C%22AB2Xq4hcilCERh73EFWJVHXx-io2lhh1EhC8UD8%22%5D%5D%5D%5D",
      method: "POST",
      mode: "cors"
    }
  );
}, ${1000 * i});
`;
  };

  const generateScript = (sources, newParams) => {
    let srcs = sources.filter(
      (s) => s.syntax_created === 0 && s.source_status_id === 1
    );
    let ret = "";
    let i = 0;
    for (let src of srcs) {
      for (let langstx of language_syntaxes) {
        if (src.languages.find((l) => l.id === langstx.language_id))
          ret += scrptStx(
            src.url,
            langstx.syntax,
            newParams.x,
            newParams.mail,
            (i += parseInt(Math.random() * 4 + 6)),
            langstx.language_id
          );
      }
    }
    return ret;
  };

  useEffect(() => {
    if (params.x && params.mail)
      setParams({ ...params, script: generateScript(sources, params) });
  }, [sources]);

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (e) => {
    let newParams;
    if (e && e.target) {
      newParams = { ...params, [e.target.name]: e.target.value };
    } else {
      newParams = { ...params, mail: e ? e.email.replace("@", "%40") : "" };
    }
    if (newParams.mail && newParams.x) {
      newParams = { ...newParams, script: generateScript(sources, newParams) };
    } else
      newParams = {
        ...newParams,
        script: ""
      };
    setParams(newParams);
  };
  const handleSave = async (article) => {
    await navigator.clipboard.writeText(params.script);
    setSuccess("Scripts succesfully copied");
    handleClose();
  };

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth={true}
      style={{ left: "20px" }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open || false}
    >
      <DialogTitle
        id="max-width-dialog-title"
        color="primary"
        className={classes.formHeader}
      >
        Generate Alert Code
      </DialogTitle>

      <DialogContent dividers={true}>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="country-select-demo"
              style={{ width: "100%" }}
              options={alert_subaccounts}
              disableClearable={false}
              clearOnBlur={true}
              classes={{
                option: classes.option
              }}
              size="small"
              onChange={(e, newVal) => onChange(newVal)}
              getOptionLabel={(option) => option.email}
              renderOption={(option) => (
                <React.Fragment>{option.email}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Subaccount"
                  variant="outlined"
                  autoFocus
                  margin="dense"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off" // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>{" "}
          <Grid item xs={12} md={6}>
            <TextField
              margin="dense"
              name="x"
              label="X Value"
              value={params.x}
              onChange={onChange}
              className={classes.textField}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              multiline
              rows={10}
              margin="dense"
              name="script"
              label="Script"
              value={params.script}
              className={classes.textField}
              variant="outlined"
              fullWidth
            />
          </Grid>{" "}
        </Grid>
      </DialogContent>
      <DialogActions>
        {" "}
        <Button onClick={handleClose} variant="contained" color="secondary">
          CANCEL
        </Button>{" "}
        <Button
          onClick={(e) => handleSave()}
          variant="contained"
          color="primary"
        >
          COPY ALERT CODE
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SyntaxForm;
