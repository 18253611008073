import React, { Fragment, useState, useEffect } from "react";
import clsx from "clsx";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  Icon,
  CircularProgress,
  Dialog,
  useMediaQuery,
  useTheme,
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  TextField,
  InputAdornment,
  Input,
  IconButton,
  InputLabel,
  OutlinedInput,
  Select,
  FormControl,
  MenuItem,
  ListItemText,
  Checkbox
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  getJurTags,
  clearErrorTags,
  setCurrentJurTag,
  setOpenJurTag,
  updateJurTag,
  clearCurrentJurTag
} from "../actions/tags";

import { getLanguages } from "../actions/related";
import ChipInput from "material-ui-chip-input";

const emptyJurTag = {
  rules: [],
  language_id: null
};

const JurTagForm = ({
  current,
  clearCurrentJurTag,
  updateJurTag,
  open,
  addJurTag,
  loading,
  allLanguages,
  getLanguages
}) => {
  const classes = useStyles();
  const [jur_tag, setJurTag] = useState(emptyJurTag);

  const { jur_triggers } = jur_tag;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };
  const theRoles = [
    { id: 1, name: "Admin" },
    { id: 2, name: "Editor" }
  ];

  useEffect(() => {
    if (!allLanguages || !allLanguages.length) getLanguages();
    if (current !== null) {
      setJurTag(current);
    } else {
      setJurTag(emptyJurTag);
    }
  }, [current, open]);

  useEffect(() => {
    console.log(jur_tag);
  }, [jur_tag]);

  function handleClose() {
    clearCurrentJurTag();
  }
  function onSubmit(e) {
    e.preventDefault();

    try {
      updateJurTag(jur_tag);
    } catch (error) {
      console.log(error);
    }

    clearCurrentJurTag();
  }

  const onChange = (e, newVal = null) => {
    console.log(newVal);
    if (e === "jurisdiction") {
      setJurTag({
        ...jur_tag,
        jurisdiction_id: newVal ? newVal.id : ""
      });
    } else if (e === "language") {
      setJurTag({
        ...jur_tag,
        language_id: newVal.id
      });
    } else {
      let val = e.target.value;
      if (e.target.name === "languages") {
        val = val.map((v) => {
          if (typeof v === "string")
            return {
              id: v,
              name: allLanguages.find((r) => r.id === v).name
            };
          else return v;
        });
      }

      setJurTag({ ...jur_tag, [e.target.name]: val });
    }
  };

  const handleChangeJur = (chips) => {
    setJurTag({
      ...jur_tag,
      jur_triggers: jur_tag.jur_triggers
        .filter((jt) => jt.con === 1)
        .concat(chips.map((c) => ({ name: c, con: 0 })))
    });
  };

  const handleChangeCon = (chips) => {
    setJurTag({
      ...jur_tag,
      jur_triggers: jur_tag.jur_triggers
        .filter((jt) => jt.con === 0)
        .concat(chips.map((c) => ({ name: c, con: 1 })))
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />

      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        fullScreen={true}
        style={
          !useMediaQuery(useTheme().breakpoints.down("sm"))
            ? { width: "80%", height: "80%", margin: "auto" }
            : {}
        }
        open={current || open ? true : false}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        scroll="paper"
      >
        <DialogTitle
          id="max-width-dialog-title"
          color="primary"
          className={classes.formHeader}
        >
          {!current && open
            ? "New Entry"
            : current && !open
            ? "Edit Entry: " + current.original_header
            : ""}
        </DialogTitle>

        <DialogContent dividers={true}>
          <form onSubmit={onSubmit}>
            <input type="submit" style={{ display: "none" }} />
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={12}>
                <ChipInput
                  label={"JUR Triggers"}
                  defaultValue={
                    jur_triggers
                      ? jur_triggers
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .filter((jt) => jt.con === 0)
                          .map((jt) => jt.name)
                      : []
                  }
                  fullWidth
                  onChange={(chips) => handleChangeJur(chips)}
                />
              </Grid>{" "}
              <Grid item xs={12} md={12}>
                <ChipInput
                  label={"CON Triggers"}
                  defaultValue={
                    jur_triggers
                      ? jur_triggers
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .filter((jt) => jt.con === 1)
                          .map((jt) => jt.name)
                      : []
                  }
                  fullWidth
                  onChange={(chips) => handleChangeCon(chips)}
                />
              </Grid>{" "}
            </Grid>{" "}
          </form>
        </DialogContent>
        <DialogActions className={classes.formFooter}>
          <Button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Save
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            disabled={loading}
          >
            Cancel
          </Button>
        </DialogActions>
        <Fragment>
          <CssBaseline />
        </Fragment>
      </Dialog>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650
  },
  progress: {
    position: "absolute",
    top: "60px",
    right: "20px"
  },
  modalPaper: {
    width: "10%"
  },

  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  formHeader: {
    backgroundColor: "#002060",
    color: "white"
  },
  formFooter: {
    backgroundColor: "gainsboro",
    padding: "10px"
  }
}));

const mapStateToProps = (state) => ({
  jur_tags: state.tags.jur_tags,
  loading: state.tags.loading,
  error: state.tags.error,
  current: state.tags.current_jur_tag,
  open: state.tags.open_jur_tag,
  allLanguages: state.related.languages
});

const mapDispatchToProps = (dispatch) => ({
  getJurTags: () => dispatch(getJurTags()),
  clearErrorTags: () => dispatch(clearErrorTags()),
  setCurrentJurTag: (jur_tag) => dispatch(setCurrentJurTag(jur_tag)),
  clearCurrentJurTag: () => dispatch(clearCurrentJurTag()),
  updateJurTag: (jur_tag) => dispatch(updateJurTag(jur_tag)),
  getLanguages: () => dispatch(getLanguages())
});
export default connect(mapStateToProps, mapDispatchToProps)(JurTagForm);
