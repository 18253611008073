import axios from "axios";
import { getUntrackedInbox, removeFromUSI } from "./articles";

export const deleteSource = (id) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_SOURCES", payload: true });
    dispatch({ type: "SET_BYPASS_IO_SOURCES", payload: true });
    try {
      const responseData = await axios.delete(
        process.env.REACT_APP_BACKEND_URL + "/sources/" + id
      );
      dispatch({ type: "DELETE_SOURCE", payload: id });
      dispatch({ type: "LOADING_SOURCES", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_SOURCES", payload: err });
      dispatch({ type: "SET_BYPASS_IO_SOURCES", payload: false });
      dispatch({ type: "LOADING_SOURCES", payload: false });
    }
  };
};

export const setBypassIoSources = (should) => ({
  type: "SET_BYPASS_IO_SOURCES",
  payload: should
});

export const setOpenSource = (open) => ({
  type: "SET_OPEN_SOURCE",
  payload: open
});

export const updateSource = (source) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_SOURCES", payload: true });
    dispatch({ type: "SET_BYPASS_IO_SOURCES", payload: true });
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/sources",
        source
      );
      dispatch({ type: "UPDATE_SOURCE", payload: source });
      dispatch({ type: "LOADING_SOURCES", payload: false });
      dispatch({ type: "CLEAR_CURRENT_SOURCE" });
    } catch (err) {
      dispatch({ type: "ERROR_SOURCES", payload: err });
      dispatch({ type: "SET_BYPASS_IO_SOURCES", payload: false });
      dispatch({ type: "LOADING_SOURCES", payload: false });
    }
  };
};

export const addSource = (source, updateUsi = false) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_SOURCES", payload: true });
    dispatch({ type: "SET_BYPASS_IO_SOURCES", payload: true });
    let id = source.article_id;
    delete source.article_id;

    try {
      const responseData = await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/sources",
        {
          ...source
        }
      );

      dispatch({ type: "ADD_SOURCE", payload: responseData.data.source });

      if (updateUsi) {
        await axios.post(
          process.env.REACT_APP_BACKEND_URL + "/articles/addSourceToArticles",
          { source: responseData.data.source }
        );
        //dispatch(getUntrackedInbox());
      }
      if (id) {
        dispatch(removeFromUSI(id));
      }
      dispatch({ type: "LOADING_SOURCES", payload: false });
      dispatch({ type: "SET_OPEN_SOURCE", payload: false });
      dispatch({ type: "CLEAR_CURRENT_SOURCE" });
    } catch (err) {
      dispatch({ type: "ERROR_SOURCES", payload: err });
      dispatch({ type: "SET_BYPASS_IO_SOURCES", payload: false });
      dispatch({ type: "LOADING_SOURCES", payload: false });
    }
  };
};

export const confirmAlertsImport = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_SOURCES", payload: true });
    dispatch({ type: "SET_BYPASS_IO_SOURCES", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/sources/confirmAlertsImport"
      );

      await dispatch({ type: "CONFIRM_ALERTS_IMPORT" });
      dispatch({
        type: "SUCCESS_SOURCES",
        payload: "Sources alerts marked as added to Google"
      });

      dispatch({ type: "LOADING_SOURCES", payload: false });
      dispatch({ type: "SET_OPEN_SOURCE", payload: false });
      dispatch({ type: "CLEAR_CURRENT_SOURCE" });
    } catch (err) {
      dispatch({ type: "ERROR_SOURCES", payload: err });
      dispatch({ type: "SET_BYPASS_IO_SOURCES", payload: false });
      dispatch({ type: "LOADING_SOURCES", payload: false });
    }
  };
};
export const setSuccessSources = (msg) => ({
  type: "SUCCESS_SOURCES",
  payload: msg
});

export const setCurrentSource = (source) => ({
  type: "CURRENT_SOURCE",
  payload: source
});

export const setFilters = (filters) => ({
  type: "FILTER_SOURCE",
  payload: filters
});

export const clearCurrentSource = () => ({ type: "CLEAR_CURRENT_SOURCE" });

export const clearErrorSources = () => ({ type: "CLEAR_ERROR_SOURCES" });

export const getSources = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_SOURCES", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/sources"
      );

      dispatch({ type: "GET_SOURCES", payload: responseData.data.sources });
      dispatch({ type: "LOADING_SOURCES", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_SOURCES", payload: err });
      dispatch({ type: "LOADING_SOURCES", payload: false });
    }
  };
};
