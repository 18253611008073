import axios from "axios";
import { getUntrackedInbox } from "./articles";

export const deleteTag = (id) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_TAGS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_TAGS", payload: true });
    try {
      const responseData = await axios.delete(
        process.env.REACT_APP_BACKEND_URL + "/tags/" + id
      );
      dispatch({ type: "DELETE_TAG", payload: id });
      dispatch({ type: "LOADING_TAGS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_TAGS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_TAGS", payload: false });
      dispatch({ type: "LOADING_TAGS", payload: false });
    }
  };
};

export const setBypassIoTags = (should) => ({
  type: "SET_BYPASS_IO_TAGS",
  payload: should
});




export const setOpenTag = (open) => ({
  type: "SET_OPEN_TAG",
  payload: open
});

export const setOpenTier = (open) => ({
  type: "SET_OPEN_TIER",
  payload: open
});


export const setOpenJurTag = (open) => ({
  type: "SET_OPEN_JUR_TAG",
  payload: open
});

export const updateTag = (tag) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_TAGS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_TAGS", payload: true });
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/tags",
        tag
      );
      dispatch({ type: "UPDATE_TAG", payload: tag });
      dispatch({ type: "LOADING_TAGS", payload: false });
      dispatch({ type: "CLEAR_CURRENT_TAG" });
    } catch (err) {
      dispatch({ type: "ERROR_TAGS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_TAGS", payload: false });
      dispatch({ type: "LOADING_TAGS", payload: false });
    }
  };
};

export const updateTier = (tier) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_TAGS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_TAGS", payload: true });
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/tags/tiers",
        tier
      );
      dispatch({ type: "UPDATE_TIER", payload: tier });
      dispatch({ type: "LOADING_TAGS", payload: false });
      dispatch({ type: "CLEAR_CURRENT_TIER" });
    } catch (err) {
      dispatch({ type: "ERROR_TAGS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_TAGS", payload: false });
      dispatch({ type: "LOADING_TAGS", payload: false });
    }
  };
};

export const updateJurTag = (jur_tag) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_TAGS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_TAGS", payload: true });
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/tags/jur_tags",
        jur_tag
      );
      dispatch({ type: "UPDATE_JUR_TAG", payload: jur_tag });
      dispatch({ type: "LOADING_TAGS", payload: false });
      dispatch({ type: "CLEAR_CURRENT_JUR_TAG" });
    } catch (err) {
      dispatch({ type: "ERROR_TAGS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_TAGS", payload: false });
      dispatch({ type: "LOADING_TAGS", payload: false });
    }
  };
};


export const addTag = (tag, updateUsi = false) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_TAGS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_TAGS", payload: true });
    try {
      const responseData = await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/tags",
        tag
      );

      dispatch({ type: "ADD_TAG", payload: responseData.data.tag });

      dispatch({ type: "LOADING_TAGS", payload: false });
      dispatch({ type: "SET_OPEN_TAG", payload: false });
      dispatch({ type: "CLEAR_CURRENT_TAG" });
    } catch (err) {
      dispatch({ type: "ERROR_TAGS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_TAGS", payload: false });
      dispatch({ type: "LOADING_TAGS", payload: false });
    }
  };
};

export const confirmAlertsImport = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_TAGS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_TAGS", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/tags/confirmAlertsImport"
      );

      await dispatch({ type: "CONFIRM_ALERTS_IMPORT" });
      dispatch({
        type: "SUCCESS_TAGS",
        payload: "Tags alerts marked as added to Google"
      });

      dispatch({ type: "LOADING_TAGS", payload: false });
      dispatch({ type: "SET_OPEN_TAG", payload: false });
      dispatch({ type: "CLEAR_CURRENT_TAG" });
    } catch (err) {
      dispatch({ type: "ERROR_TAGS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_TAGS", payload: false });
      dispatch({ type: "LOADING_TAGS", payload: false });
    }
  };
};
export const setSuccessTags = (msg) => ({
  type: "SUCCESS_TAGS",
  payload: msg
});

export const setCurrentTag = (tag) => ({
  type: "CURRENT_TAG",
  payload: tag
});

export const setCurrentTier = (tier) => ({
  type: "CURRENT_TIER",
  payload: tier
});

export const setCurrentJurTag = (jur_tag) => ({
  type: "CURRENT_JUR_TAG",
  payload: jur_tag
});

export const setFilters = (filters) => ({
  type: "FILTER_TAG",
  payload: filters
});

export const clearCurrentTag = () => ({ type: "CLEAR_CURRENT_TAG" });
export const clearCurrentTier = () => ({ type: "CLEAR_CURRENT_TIER" });
export const clearCurrentJurTag = () => ({ type: "CLEAR_CURRENT_JUR_TAG" });

export const clearErrorTags = () => ({ type: "CLEAR_ERROR_TAGS" });

export const getTags = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_TAGS", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/tags"
      );

      dispatch({ type: "GET_TAGS", payload: responseData.data.tags });
      dispatch({ type: "LOADING_TAGS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_TAGS", payload: err });
      dispatch({ type: "LOADING_TAGS", payload: false });
    }
  };
};


export const getTiers = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_TAGS", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/tags/tiers"
      );

      dispatch({ type: "GET_TIERS", payload: responseData.data.tiers });
      dispatch({ type: "LOADING_TAGS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_TAGS", payload: err });
      dispatch({ type: "LOADING_TAGS", payload: false });
    }
  };
};


export const getJurTags = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_TAGS", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/tags/jur_tags"
      );

      dispatch({ type: "GET_JUR_TAGS", payload: responseData.data.jur_tags });
      dispatch({ type: "LOADING_TAGS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_TAGS", payload: err });
      dispatch({ type: "LOADING_TAGS", payload: false });
    }
  };
};
