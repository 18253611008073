const initialState = {
  languages: [],
  all_languages: [],
  source_types: [],
  source_statuses: [],
  syntax_types: [],
  jurisdictions: [],
  language_syntaxes: [],
  tags: [],
  alert_subaccounts: [],
  error: null,
  loading: true
};

const related = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LANGUAGES":
      return {
        ...state,
        languages: action.payload.languages,
        all_languages: action.payload.all_languages
      };
    case "GET_SOURCE_TYPES":
      return { ...state, source_types: action.payload };
    case "GET_SOURCE_STATUSES":
      return { ...state, source_statuses: action.payload };
    case "GET_JURISDICTIONS":
      return { ...state, jurisdictions: action.payload };
    case "GET_TAGS":
      return { ...state, tags: action.payload };
    case "GET_SYNTAX_TYPES":
      return { ...state, syntax_types: action.payload };
    case "GET_ALERT_SUBACCOUNTS":
      return { ...state, alert_subaccounts: action.payload };
    case "GET_LANGUAGE_SYNTAXES":
      return { ...state, language_syntaxes: action.payload };
    case "LOADING_RELATED":
      return { ...state, loading: action.payload };

    default:
      return state;
  }
};

export default related;
