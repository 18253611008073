import React, { Fragment } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  Paper,
  DialogActions,
  Button,
  IconButton,
  Icon
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import { removeTag } from "../actions/articles";

const useStyles = makeStyles((theme) => ({
  buttonAddJur: {
    backgroundColor: "rgba(0,32,96,0.5)",
    color: "white",
    "&:hover": {
      backgroundColor: "rgba(0,32,96,0.8)"
    },
    marginTop: "0px"
  },
  buttonAddJurNoTag: {
    backgroundColor: "rgba(0,32,96,0.5)",
    color: "white",
    "&:hover": {
      backgroundColor: "rgba(0,32,96,0.8)"
    },
    marginTop: "-24px"
  },
  buttonRemoveJur: {
    backgroundColor: "rgba(139,0,0,0.5)",
    color: "white",
    "&:hover": {
      backgroundColor: "rgba(139,0,0,0.8)"
    },
    paddingTop: "3px",
    marginTop: "0px"
  }
}));

function TagButtons(props) {
  const { show, removeTag, article, tag, setArticleNewTag, posfix } = props;

  const classes = useStyles();

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: "0px",
        backgroundColor: "rgba(256,256,256,0.8)",
        color: "black",
        display: show ? "block" : "none"
      }}
    >
      <center>
        {tag && (
          <IconButton
            size="small"
            onClick={(e) => removeTag(article, tag, posfix)}
            variant="outlined"
            className={classes.buttonRemoveJur}
          >
            <Icon style={{ fontSize: "0.9rem", paddingTop: "1px" }}>close</Icon>
          </IconButton>
        )}{" "}
        <IconButton
          iconStyle={{ fontSize: "0.7rem" }}
          size="small"
          onClick={(e) => setArticleNewTag(article)}
          className={tag ? classes.buttonAddJur : classes.buttonAddJurNoTag}
        >
          <Icon style={{ fontSize: "0.9rem", paddingTop: "1px" }}>add</Icon>
        </IconButton>
      </center>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  removeTag: (article, tag, posfix) => dispatch(removeTag(article, tag, posfix))
});
export default connect(mapStateToProps, mapDispatchToProps)(TagButtons);
