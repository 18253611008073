import React, { useContext, Fragment } from "react";
import { NavLink } from "react-router-dom";

import { AuthContext } from "../../context/auth-context";
const AuthNavLink = (props) => {

  const auth = useContext(AuthContext);
  
  return (
    <Fragment>
      {(!props.role ||
        (auth.roles && auth.roles.indexOf(props.role) !== -1) ||
        auth.roles.indexOf('Admin') !== -1) && (
        <NavLink title={props.title} to={props.to}>
          {props.children}
        </NavLink>
      )}
    </Fragment>
  );
};

export default AuthNavLink;
