import React from "react";

import { LinearProgress } from "@material-ui/core";

const ProgressBar = (props) => {
  return (
    <LinearProgress
      color="secondary"
      style={{
        zIndex: "1000",
        position: "fixed",
        backgroundColor: "#002060",
        top: 0,
        width: "100%"
      }}
    />
  );
};

export default ProgressBar;
