import React, { useState, useContext, createRef, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import Card from "../shared/components/UIElements/Card";
import ErrorSnack from "../shared/components/UIElements/ErrorSnack";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner";

import { useHttpClient } from "../shared/hooks/http-hook";
import { AuthContext } from "../shared/context/auth-context";
import "./Auth.css";

import { FormControl, Button, TextField, Grid } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";

const Auth = () => {
  const auth = useContext(AuthContext);
  const [isLoginMode, setIsLoginMode] = useState(true);
  const { isLoading, error, sendRequest, clearError, setIsLoading } = useHttpClient();

  const [form, setForm] = useState({});

  const recaptchaRef = createRef();

  useEffect(() => {
    if (form.captcha) {
      sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/users/login",
        "POST",
        JSON.stringify({
          email: form.email,
          password: form.password,
          captcha: form.captcha,
        }),
        {
          "Content-Type": "application/json",
        }
      )
        .then((responseData) => {
          auth.login(responseData.userId, responseData.token, null, responseData.roles);
        })
        .catch((e) => {});
    }
  }, [form.captcha]);

  const inputHandler = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  function onCaptcha(value) {
    setForm({ ...form, captcha: value });
  }

  const authSubmitHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (form.captcha) recaptchaRef.current.reset();
    recaptchaRef.current.execute();
  };

  return (
    <React.Fragment>
      <ErrorSnack error={error && { msg: error }} clearError={clearError} />
      <Card className="authentication">
        {isLoading && <LoadingSpinner asOverlay />}
        <center>
          {" "}
          <img src="/img/logo_small.png" />
        </center>
        <br />
        <form onSubmit={authSubmitHandler}>
          <FormControl>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <FormControl>
                  <TextField
                    variant="outlined"
                    id="email"
                    type="email"
                    name="email"
                    label="E-Mail"
                    style={{ width: "280px" }}
                    value={form.email}
                    onChange={inputHandler}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <TextField
                    style={{ width: "280px" }}
                    variant="outlined"
                    id="password"
                    name="password"
                    type="password"
                    label="Password"
                    value={form.password}
                    onChange={inputHandler}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Button
                  style={{ width: "280px", backgroundColor: '#002060', color:'white' }}
                  variant="contained"
                  
                  type="submit"
                >
                  {isLoginMode ? "LOGIN" : "SIGNUP"}
                </Button>
              </Grid>
            </Grid>
          </FormControl>

          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            ref={recaptchaRef}
            size="invisible"
            onChange={onCaptcha}
          />
        </form>
      </Card>
    </React.Fragment>
  );
};

export default Auth;
