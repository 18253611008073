import React, { useEffect, useState, useContext, Fragment } from "react";
import { connect } from "react-redux";
import Calendar from "react-calendar";

import {
  getLanguages,
  getSourceTypes,
  getSourceStatuses,
  getJurisdictions,
  getAlertSubaccounts
} from "../actions/related";

import { AuthContext } from "../shared/context/auth-context";

import ErrorSnack from "../shared/components/UIElements/ErrorSnack";
import EnhancedTable from "../shared/components/UIElements/EnhancedTable";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  Icon,
  Breadcrumbs,
  Link,
  TextField,
  Grid,
  Dialog
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useConfirm } from "material-ui-confirm";

import ProgressBar from "../shared/components/UIElements/ProgressBar";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import { getSources } from "../actions/sources";
import AlertLog from "./AlertLog";

function SimilarityTest(props) {
  const { onClose, selectedArticle, open } = props;

  const [text, setText] = useState({ t1: "", t2: "" });

  const onChange = (e) => {
    setText({ ...text, [e.target.name]: e.target.value });
  };

  return (
    <React.Fragment>
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="xl">

          <Grid container spacing={0}>
            <Grid item xs={12} md={1}></Grid>
            <Grid item xs={12} md={4}>
              Text 1:{" "}
              <TextField
                name="t1"
                onChange={onChange}
                value={text.t1}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={1}></Grid>
            <Grid item xs={12} md={4}>
              Text 2:{" "}
              <TextField
                name="t2"
                onChange={onChange}
                value={text.t2}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={1}></Grid>
          </Grid>
        </Container>
      </React.Fragment>
    </React.Fragment>
  );
}

export default SimilarityTest;
