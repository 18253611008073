import axios from "axios";

export const deleteDbUser = (id) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_DB_USERS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_DB_USERS", payload: true });
    try {
      const responseData = await axios.delete(
        process.env.REACT_APP_BACKEND_URL + "/db_users/" + id
      );
      dispatch({ type: "DELETE_DB_USER", payload: id });
      dispatch({ type: "LOADING_DB_USERS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_DB_USERS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_DB_USERS", payload: false });
      dispatch({ type: "LOADING_DB_USERS", payload: false });
    }
  };
};
/*
option=1 MONITOR
option=3 DB
*/
export const approveDbUser = (id) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_DB_USERS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_DB_USERS", payload: true });
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/db_users/approve_reject",
        {
          id,
          approve: true,
        }
      );
      dispatch({ type: "APPROVE_DB_USER", payload: { id } });
      dispatch({ type: "LOADING_DB_USERS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_DB_USERS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_DB_USERS", payload: false });
      dispatch({ type: "LOADING_DB_USERS", payload: false });
    }
  };
};

export const rejectDbUser = (id, noMail = false) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_DB_USERS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_DB_USERS", payload: true });
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/db_users/approve_reject",
        {
          id,
          reject: true,
          noMail,
        }
      );
      dispatch({ type: "REJECT_DB_USER", payload: { id } });
      dispatch({ type: "LOADING_DB_USERS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_DB_USERS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_DB_USERS", payload: false });
      dispatch({ type: "LOADING_DB_USERS", payload: false });
    }
  };
};

export const setBypassIoDbUsers = (should) => ({
  type: "SET_BYPASS_IO_DB_USERS",
  payload: should,
});

export const setOpenDbUser = (open) => ({
  type: "SET_OPEN_DB_USER",
  payload: open,
});

export const updateDbUser = (db_user) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_DB_USERS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_DB_USERS", payload: true });
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/db_users",
        db_user
      );
      dispatch({ type: "UPDATE_DB_USER", payload: db_user });
      dispatch({ type: "LOADING_DB_USERS", payload: false });
      dispatch({ type: "CLEAR_CURRENT_DB_USER" });
    } catch (err) {
      dispatch({ type: "ERROR_DB_USERS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_DB_USERS", payload: false });
      dispatch({ type: "LOADING_DB_USERS", payload: false });
    }
  };
};

export const addDbUser = (db_user) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_DB_USERS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_DB_USERS", payload: true });
    try {
      const responseData = await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/db_users",
        db_user
      );
      dispatch({ type: "ADD_DB_USER", payload: db_user });
      dispatch({ type: "LOADING_DB_USERS", payload: false });
      dispatch({ type: "SET_OPEN_DB_USER", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_DB_USERS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_DB_USERS", payload: false });
      dispatch({ type: "LOADING_DB_USERS", payload: false });
    }
  };
};

export const setCurrentDbUser = (db_user) => ({
  type: "CURRENT_DB_USER",
  payload: db_user,
});

export const clearCurrentDbUser = () => ({ type: "CLEAR_CURRENT_DB_USER" });

export const clearErrorDbUsers = () => ({ type: "CLEAR_ERROR_DB_USERS" });

export const getDbUsers = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_DB_USERS", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/db_users"
      );

      dispatch({ type: "GET_DB_USERS", payload: responseData.data.db_users });
      dispatch({ type: "LOADING_DB_USERS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_DB_USERS", payload: err });
      dispatch({ type: "LOADING_DB_USERS", payload: false });
    }
  };
};

export const getDbUserLanguages = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_DB_USERS", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/db_users/db_user_details"
      );

      dispatch({
        type: "GET_DB_USER_LANGUAGES",
        payload: responseData.data.languages,
      });
      dispatch({
        type: "GET_DB_USER_DETAILS",
        payload: responseData.data,
      });
      dispatch({ type: "LOADING_DB_USERS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_DB_USERS", payload: err });
      dispatch({ type: "LOADING_DB_USERS", payload: false });
    }
  };
};

export const addConfirmed = () => ({ type: "ADD_CONFIRMED" });
export const addRejected = () => ({ type: "ADD_REJECTED" });
