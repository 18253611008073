import {
  createMuiTheme,
  MuiThemeProvider
} from "@material-ui/core/styles";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import App from "./App";
import "./index.css";
import rootReducer from "./reducers/index";




const theme = createMuiTheme({
  palette: {
    background: {
      default: "#65849e"
    }
  },
  typography: {
    fontFamily: "Roboto Condensed",
    h1: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "navy"
    },
    body1: {
      fontFamily: "Roboto Condensed"
    }
  },
  custom: {
    myOwnComponent: {
      margin: "10px 10px",
      backgroundColor: "lightgreen"
    }
  }
});

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25
    })) ||
  compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);
