import React, { useState, Fragment, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import { connect } from "react-redux";
import EnhancedTable from "../shared/components/UIElements/EnhancedTable";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ExpansionPanel, Grid, Icon, Snackbar, IconButton, Paper, Dialog, Slide, TextField } from "@material-ui/core";
import moment from "moment";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import DateFnsUtils from "@date-io/moment"; // choose your lib
import { DatePicker, TimePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { setFilters } from "../actions/articles";

import { getUserLanguages } from "../actions/users";

import Autocomplete from "@material-ui/lab/Autocomplete";

const rainbowColors = [
  "#FF0018",
  "#FFA52C",
  "#FFFF41",
  "#008018",
  "#0000F9",
  "#86007D",
  "#FF0018",
  "#FFA52C",
  "#FFFF41",
  "#008018",
  "#0000F9",
  "#86007D"
];

const inboxTabs = ["MOU", "CSO", "DOG", "IHR", "OTHER"];
const inboxTabsIds = {
  MOU: { ids: [1], color: "#4eb3d3" },
  CSO: { ids: [2], color: "#7bccc4" },
  GOV: { ids: [3, 8, 9, 10, 11], color: "#7bccc4" },
  DOG: { ids: [7, 14], color: "#a8ddb5" },
  IHR: { ids: [4], color: "#ccebc5" },
  OTHER: { ids: [5, 6, 12, 13], color: "#f0f9e8" }
};
const tierTabs = ["#2c7bb6", "#abd9e9", "#ffffbf", "#fdae61", "#d7191c"];

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(20),
    flexBasis: "13.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    verticalAlign: "middle",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}));

const shiftPickerDateToTZDate = (pickerDateMoment) => {
  let pickerDate = pickerDateMoment.toDate();
  let pickerOffset = pickerDate.getTimezoneOffset();
  let utcDate = new Date();
  utcDate.setTime(pickerDate.getTime() - pickerOffset * 60000);

  let tzOffset = moment.tz(pickerDate, "UTC").utcOffset();
  let tzDate = new Date();
  tzDate.setTime(utcDate.getTime() - tzOffset * 60000);
  return moment(tzDate);
};

const shiftTzDateToPickerDate = (tzDateMD) => {
  let tzDate = tzDateMD._isAMomentObject ? tzDateMD.toDate() : tzDateMD;

  let tzUTCOffset = moment.tz(tzDate, "UTC").utcOffset();
  let utcDate = new Date();
  utcDate.setTime(tzDate.getTime() + tzUTCOffset * 60000);

  let pickerDate = new Date();
  let pickerOffset = pickerDate.getTimezoneOffset();
  pickerDate.setTime(utcDate.getTime() + pickerOffset * 60000);
  return moment(pickerDate);
};

function InboxFilters({
  filter,
  setFilters,
  date,
  languages,
  setDate,
  source_types,
  setPage,
  inbox_articles,
  getUserLanguages,
  user_languages,
  show_articles,
  loading,
  tier,
  setTier
}) {
  const classes = useStyles();

  const [filtersTmp, setFiltersTmp] = React.useState({
    header: "",
    tier: 2,
    sou: "MOU",
    languages: []
  });

  useEffect(() => {
    if (!user_languages || !user_languages.length) getUserLanguages();
    else {
      setFiltersTmp({
        ...filter.filters,
        languages: user_languages
      });
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setFilters({
        ...filtersTmp,
        ...(filtersTmp.header && { tier: null })
      });
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [filtersTmp.header]);

  useEffect(() => {
    setPage(1);
  }, [filter.filters.sou, filter.filters.tier]);

  useEffect(() => {
    setFiltersTmp({
      ...filter.filters,
      languages: user_languages
    });
  }, [user_languages]);

  useEffect(() => {
    setFilters({
      ...filter.filters,
      languages: filtersTmp.languages
    });
  }, [filtersTmp.languages]);

  return (
    <Fragment>
      {(inbox_articles.length > 0 || (!loading && inbox_articles.length === 0)) && (
        <Grid container spacing={0}>
          <Grid item xs={12} md={2}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Typography variant="h1">
                    <DatePicker
                      value={shiftTzDateToPickerDate(date)}
                      onChange={(date) => setDate(shiftPickerDateToTZDate(date))}
                      inputVariant="outlined"
                      margin="dense"
                      style={{ backgroundColor: "white" }}
                    />
                  </Typography>
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5}>
            {languages && (
              <Autocomplete
                id="country-select-demo"
                style={{ width: "100%" }}
                options={languages}
                disableClearable={false}
                classes={{
                  option: classes.option
                }}
                multiple
                size="small"
                onChange={(e, newVal) => setFiltersTmp({ ...filter.filters, languages: newVal })}
                autoHighlight
                value={filtersTmp.languages || []}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => value.id === option.id}
                renderOption={(option) => <React.Fragment>{option.name}</React.Fragment>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Languages"
                    variant="outlined"
                    margin="dense"
                    style={{ backgroundColor: "white" }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off",
                      form: {
                        autocomplete: "off"
                      }
                    }}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} md={5} style={{ textAlign: "right", paddingTop: "8px" }}>
            <TextField
              variant="outlined"
              size="small"
              style={{
                width: "84%",
                backgroundColor: "white"
              }}
              value={filtersTmp.header || ""}
              placeholder="Search..."
              onChange={(e) => setFiltersTmp({ ...filter.filters, header: e.target.value })}
            />
            <Button variant="contained" style={{ width: "10%", marginLeft: "10px" }} color="primary">
              <Icon>search</Icon>
            </Button>
          </Grid>
          <Grid item xs={12} md={12} style={{ marginTop: "10px" }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} style={{ textAlign: "left" }}>
                {tierTabs.map((color, index) => (
                  <Button
                    key={index}
                    variant="contained"
                    style={{
                      marginRight: "10px",
                      fontWeight: "bold",
                      marginTop: "2px",
                      backgroundColor: color,
                      ...(filter.filters.tier === index + 1 && {
                        border: "2px solid black"
                      }),
                      textTransform: "unset"
                    }}
                    onClick={(e) =>
                      setFilters({
                        ...filter.filters,
                        tier: index + 1,
                        sou: index + 1 === 1 ? undefined : "MOU"
                      })
                    }
                  >
                    Tier {index + 1} (
                    {
                      inbox_articles.filter(
                        (a) =>
                          a.tier === index + 1 &&
                          (!filter.filters.languages ||
                            !filter.filters.languages.length ||
                            filter.filters.languages.find((l) => l.id === a.detected_language))
                      ).length
                    }
                    )
                  </Button>
                ))}
              </Grid>
              <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
                {inboxTabs.map((souFilter, index) => (
                  <Button
                    key={index}
                    variant="contained"
                    disabled={filter.filters.tier === 1}
                    style={{
                      marginLeft: "10px",
                      marginTop: "2px",
                      fontWeight: "bold",
                      textTransform: "unset",
                      ...(filter.filters.sou === souFilter && {
                        border: "2px solid black"
                      }),
                      backgroundColor: inboxTabsIds[souFilter].color
                    }}
                    onClick={(e) => setFilters({ ...filter.filters, sou: souFilter })}
                  >
                    {souFilter} (
                    {
                      inbox_articles.filter(
                        (a) =>
                          (!filter.filters.tier || a.tier === filter.filters.tier) &&
                          inboxTabsIds[souFilter].ids.indexOf(a.source.source_type_id) !== -1 &&
                          (!filter.filters.languages ||
                            !filter.filters.languages.length ||
                            filter.filters.languages.find((l) => l.id === a.detected_language))
                      ).length
                    }
                    )
                  </Button>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}
const mapStateToProps = (state) => ({
  loading: state.articles.loading,
  filter: state.articles.filter,
  inbox_articles: state.articles.inbox,
  user_languages: state.users.user_languages,
  show_articles: state.articles.filter.active ? state.articles.filter.result : state.articles.inbox
});

const mapDispatchToProps = (dispatch) => ({
  setFilters: (filters) => dispatch(setFilters(filters)),
  getUserLanguages: () => dispatch(getUserLanguages())
});
export default connect(mapStateToProps, mapDispatchToProps)(InboxFilters);
