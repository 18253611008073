import axios from "axios";

export const deleteExclusionRule = (id) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_EXCLUSION_RULES", payload: true });
    dispatch({ type: "SET_BYPASS_IO_EXCLUSION_RULES", payload: true });
    try {
      await axios.delete(
        process.env.REACT_APP_BACKEND_URL + "/exclusion_rules/" + id
      );
      dispatch({ type: "DELETE_EXCLUSION_RULE", payload: id });
      dispatch({ type: "LOADING_EXCLUSION_RULES", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_EXCLUSION_RULES", payload: err });
      dispatch({ type: "SET_BYPASS_IO_EXCLUSION_RULES", payload: false });
      dispatch({ type: "LOADING_EXCLUSION_RULES", payload: false });
    }
  };
};

export const setBypassIoExclusionRules = (should) => ({
  type: "SET_BYPASS_IO_EXCLUSION_RULES",
  payload: should
});

export const setOpenExclusionRule = (open) => ({ type: "SET_OPEN_EXCLUSION_RULE", payload: open });

export const updateExclusionRule = (exclusion_rule) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_EXCLUSION_RULES", payload: true });
    dispatch({ type: "SET_BYPASS_IO_EXCLUSION_RULES", payload: true });
    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/exclusion_rules",
        exclusion_rule
      );
      dispatch({ type: "UPDATE_EXCLUSION_RULE", payload: exclusion_rule });
      dispatch({ type: "LOADING_EXCLUSION_RULES", payload: false });
      dispatch({ type: "CLEAR_CURRENT_EXCLUSION_RULE" });
    } catch (err) {
      dispatch({ type: "ERROR_EXCLUSION_RULES", payload: err });
      dispatch({ type: "SET_BYPASS_IO_EXCLUSION_RULES", payload: false });
      dispatch({ type: "LOADING_EXCLUSION_RULES", payload: false });
    }
  };
};

export const addExclusionRule = (exclusion_rule) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_EXCLUSION_RULES", payload: true });
    dispatch({ type: "SET_BYPASS_IO_EXCLUSION_RULES", payload: true });
    try {
      await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/exclusion_rules",
        exclusion_rule
      );
      dispatch({ type: "ADD_EXCLUSION_RULE", payload: exclusion_rule });
      dispatch({ type: "LOADING_EXCLUSION_RULES", payload: false });
      dispatch({ type: "SET_OPEN_EXCLUSION_RULE", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_EXCLUSION_RULES", payload: err });
      dispatch({ type: "SET_BYPASS_IO_EXCLUSION_RULES", payload: false });
      dispatch({ type: "LOADING_EXCLUSION_RULES", payload: false });
    }
  };
};

export const setCurrentExclusionRule = (exclusion_rule) => ({
  type: "CURRENT_EXCLUSION_RULE",
  payload: exclusion_rule
});

export const clearCurrentExclusionRule = () => ({ type: "CLEAR_CURRENT_EXCLUSION_RULE" });

export const clearErrorExclusionRules = () => ({ type: "CLEAR_ERROR_EXCLUSION_RULES" });

export const getExclusionRules = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_EXCLUSION_RULES", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/exclusion_rules"
      );

      dispatch({ type: "GET_EXCLUSION_RULES", payload: responseData.data.exclusion_rules });
      dispatch({ type: "LOADING_EXCLUSION_RULES", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_EXCLUSION_RULES", payload: err });
      dispatch({ type: "LOADING_EXCLUSION_RULES", payload: false });
    }
  };
};
