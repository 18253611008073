const initialState = {
  all: [],
  current: null,
  filtered: null,
  filter: null,
  error: null,
  loading: true,
  bypassIo: false,
  open: false,
  db_user_languages: [],
  logged_db_user: {},
};

const db_users = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_DB_USER":
      return { ...state, all: [...state.all, action.payload] };
    case "DELETE_DB_USER":
      return {
        ...state,
        all: state.all.filter((db_user) => db_user.id !== action.payload),
      };
    case "UPDATE_DB_USER":
      return {
        ...state,
        all: state.all.map((db_user) =>
          db_user.id !== action.payload.id ? db_user : action.payload
        ),
      };
    case "APPROVE_DB_USER":
      return {
        ...state,
        all: state.all.map((db_user) =>
          db_user.id !== action.payload.id
            ? db_user
            : {
                ...db_user,
                disabled: 0,
                blocked: 0,
              }
        ),
      };
    case "REJECT_DB_USER":
      return {
        ...state,
        all: state.all.map((db_user) =>
          db_user.id !== action.payload.id
            ? db_user
            : {
                ...db_user,
                blocked: 1,
              }
        ),
      };
    case "GET_DB_USERS":
      return { ...state, all: action.payload };
    case "GET_DB_USER_LANGUAGES":
      return { ...state, db_user_languages: action.payload };
    case "GET_DB_USER_DETAILS":
      return { ...state, logged_db_user: action.payload };
    case "CURRENT_DB_USER":
      return { ...state, current: action.payload };
    case "CLEAR_CURRENT_DB_USER":
      return { ...state, current: null };
    case "SET_BYPASS_IO_DB_USERS":
      return { ...state, bypassIo: action.payload };
    case "SET_OPEN_DB_USER":
      return { ...state, open: action.payload };
    case "ADD_CONFIRMED":
      if (!state.logged_db_user || !state.logged_db_user.confirmed)
        return state;
      else
        return {
          ...state,
          logged_db_user: {
            ...state.logged_db_user,
            confirmed: state.logged_db_user.confirmed + 1,
          },
        };
    case "ADD_REJECTED":
      if (!state.logged_db_user || !state.logged_db_user.rejected) return state;
      else
        return {
          ...state,
          logged_db_user: {
            ...state.logged_db_user,
            rejected: state.logged_db_user.rejected + 1,
          },
        };
    case "ERROR_DB_USERS":
      let tmpError = action.payload;
      try {
        tmpError = JSON.parse(tmpError.response.data.message);
        tmpError.list = tmpError.list
          ? "<ul>" +
            tmpError.list.map((error) => "<li>" + error + "</li>").join("") +
            "</ul>"
          : "";
      } catch (err) {
        tmpError = {
          msg: tmpError.response
            ? tmpError.response.data.message
            : tmpError.toString(),
          list: "",
        };
      }

      return { ...state, error: tmpError };

    case "CLEAR_ERROR_DB_USERS":
      return { ...state, error: null };
    case "LOADING_DB_USERS":
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default db_users;
