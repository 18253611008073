import axios from "axios";

export const deleteDeleteSegment = (id) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_DELETE_SEGMENTS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_DELETE_SEGMENTS", payload: true });
    try {
      await axios.delete(
        process.env.REACT_APP_BACKEND_URL + "/delete_segments/" + id
      );
      dispatch({ type: "DELETE_DELETE_SEGMENT", payload: id });
      dispatch({ type: "LOADING_DELETE_SEGMENTS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_DELETE_SEGMENTS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_DELETE_SEGMENTS", payload: false });
      dispatch({ type: "LOADING_DELETE_SEGMENTS", payload: false });
    }
  };
};

export const setBypassIoDeleteSegments = (should) => ({
  type: "SET_BYPASS_IO_DELETE_SEGMENTS",
  payload: should
});

export const setOpenDeleteSegment = (open) => ({ type: "SET_OPEN_DELETE_SEGMENT", payload: open });

export const updateDeleteSegment = (delete_segment) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_DELETE_SEGMENTS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_DELETE_SEGMENTS", payload: true });
    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/delete_segments",
        delete_segment
      );
      dispatch({ type: "UPDATE_DELETE_SEGMENT", payload: delete_segment });
      dispatch({ type: "LOADING_DELETE_SEGMENTS", payload: false });
      dispatch({ type: "CLEAR_CURRENT_DELETE_SEGMENT" });
    } catch (err) {
      dispatch({ type: "ERROR_DELETE_SEGMENTS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_DELETE_SEGMENTS", payload: false });
      dispatch({ type: "LOADING_DELETE_SEGMENTS", payload: false });
    }
  };
};

export const addDeleteSegment = (delete_segment) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_DELETE_SEGMENTS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_DELETE_SEGMENTS", payload: true });
    try {
      let responseData = await axios.put(process.env.REACT_APP_BACKEND_URL + "/delete_segments", delete_segment);
      dispatch({ type: "ADD_DELETE_SEGMENT", payload: responseData.data.delete_segments });
      dispatch({ type: "LOADING_DELETE_SEGMENTS", payload: false });
      dispatch({ type: "SET_OPEN_DELETE_SEGMENT", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_DELETE_SEGMENTS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_DELETE_SEGMENTS", payload: false });
      dispatch({ type: "LOADING_DELETE_SEGMENTS", payload: false });
    }
  };
};

export const setCurrentDeleteSegment = (delete_segment) => ({
  type: "CURRENT_DELETE_SEGMENT",
  payload: delete_segment
});

export const clearCurrentDeleteSegment = () => ({ type: "CLEAR_CURRENT_DELETE_SEGMENT" });

export const clearErrorDeleteSegments = () => ({ type: "CLEAR_ERROR_DELETE_SEGMENTS" });

export const getDeleteSegments = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_DELETE_SEGMENTS", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/delete_segments"
      );

      dispatch({ type: "GET_DELETE_SEGMENTS", payload: responseData.data.delete_segments });
      dispatch({ type: "LOADING_DELETE_SEGMENTS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_DELETE_SEGMENTS", payload: err });
      dispatch({ type: "LOADING_DELETE_SEGMENTS", payload: false });
    }
  };
};
