import React, { useEffect, useState, useContext, Fragment } from "react";
import { connect } from "react-redux";

import { getTiers, setCurrentTier, clearErrorTags } from "../actions/tags";
import { getLanguages } from "../actions/related";

import subscribeTo from "../utils/subscribeTo";
import { AuthContext } from "../shared/context/auth-context";
import TierForm from "./TierForm";

import ErrorSnack from "../shared/components/UIElements/ErrorSnack";
import EnhancedTable from "../shared/components/UIElements/EnhancedTable";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  Icon,
  Breadcrumbs,
  Link,
  TextField,
  Grid,
  Box
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useConfirm } from "material-ui-confirm";
import ProgressBar from "../shared/components/UIElements/ProgressBar";
import { Helmet } from "react-helmet";

const TierManager = ({
  tiers,
  loading,
  error,
  current,
  bypassIo,
  clearErrorTags,
  setCurrentTier,
  clearCurrentTier,
  getTiers,
  setBypassIoTags
}) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);

  const columns = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "Tier",
      width: "15%"
    },
    {
      id: "triggers",
      numeric: false,
      disablePadding: false,
      label: "Triggers",
      noSelect: true,
      transform: function (row) {
        return row.triggers ? (
          <Grid container direction="column">
            <Grid item container direction="row">
              {row.triggers
                .split(";")
                .sort((a, b) => a.localeCompare(b))
                .map((t, pos) => ({ t, pos }))
                /* .filter(
                    (t) =>
                      t.pos > ((i - 1) * row.triggers.split(";").length) / 6 &&
                      t.pos <= (i * row.triggers.split(";").length) / 6
                  )*/
                .map((t, pos) => (
                  <Grid item md="2" direction="row">
                    {t.t}
                  </Grid>
                ))}
            </Grid>
          </Grid>
        ) : (
          "-"
        );
      }
    }
  ];

  useEffect(() => {
    if ((!tiers || tiers.length === 0) && !error) getTiers();
  }, [loading, bypassIo]);

  const onClickEdit = (tier) => {
    setCurrentTier(tier);
  };

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tier Triggers - ASPU</title>
      </Helmet>
      {loading && <ProgressBar />}
      <ErrorSnack error={error} clearError={clearErrorTags} />
      <React.Fragment>
        <CssBaseline />
        <TierForm
          prefilled={false}
          current={current}
          clearCurrentTier={clearCurrentTier}
        />
        <Container maxWidth="xl">
          {tiers.length !== 0 && (
            <Fragment>
              <EnhancedTable
                rows={tiers}
                columns={columns}
                options={{
                  title: "Tier Manager",
                  multiple_select: false,
                  hide_pagination: true
                }}
                actions={{ edit: onClickEdit }}
              ></EnhancedTable>
            </Fragment>
          )}
        </Container>
      </React.Fragment>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableHead: {
    backgroundColor: "#eee"
  },
  progress: {
    position: "absolute",
    top: "60px",
    right: "20px"
  },
  modalPaper: {
    width: "10%"
  }
});

const mapStateToProps = (state) => ({
  tiers: state.tags.tiers,
  loading: state.tags.loading,
  error: state.tags.error,
  current: state.tags.current_tier
});

const mapDispatchToProps = (dispatch) => ({
  getTiers: () => dispatch(getTiers()),
  clearErrorTags: () => dispatch(clearErrorTags()),
  setCurrentTier: (tier) => dispatch(setCurrentTier(tier))
});
export default connect(mapStateToProps, mapDispatchToProps)(TierManager);
