const initialState = {
  all: [],
  current: null,
  filtered: null,
  filter: {
    active: false,
    filters: { tag_status: { id: 1, name: "Active" } },
    result: []
  },
  error: null,
  loading: true,
  bypassIo: false,
  open: false,
  success: null,
  tiers: [],
  current_tier: null,
  open_tier: false,
  jur_tags: [],
  current_jur_tag: null,
  open_jur_tag: false
};

const tags = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TAG":
      return { ...state, all: [...state.all, action.payload] };
    case "DELETE_TAG":
      return {
        ...state,
        all: state.all.filter((tag) => tag.id !== action.payload)
      };
    case "UPDATE_TAG":
      return {
        ...state,
        all: state.all.map((tag) =>
          tag.id !== action.payload.id ? tag : action.payload
        )
      };

    case "UPDATE_TIER":
      return {
        ...state,
        tiers: state.tiers.map((tier) =>
          tier.id !== action.payload.id ? tier : action.payload
        )
      };

    case "UPDATE_JUR_TAG":
      
      return {
        ...state,
        jur_tags: state.jur_tags.map((jt) =>
          jt.jurisdiction_id !== action.payload.jurisdiction_id
            ? jt
            : action.payload
        )
      };
    case "GET_TAGS":
      return { ...state, all: action.payload };
    case "GET_TIERS":
      return { ...state, tiers: action.payload };
    case "GET_JUR_TAGS":
      return { ...state, jur_tags: action.payload };
    case "CURRENT_TAG":
      return { ...state, current: action.payload };
    case "CURRENT_TIER":
      return { ...state, current_tier: action.payload };
    case "CURRENT_JUR_TAG":
      return { ...state, current_jur_tag: action.payload };
    case "CLEAR_CURRENT_TAG":
      return { ...state, current: null };
    case "CLEAR_CURRENT_TIER":
      return { ...state, current_tier: null };
    case "CLEAR_CURRENT_JUR_TAG":
      return { ...state, current_jur_tag: null };
    case "SET_BYPASS_IO_TAGS":
      return { ...state, bypassIo: action.payload };
    case "SET_OPEN_TAG":
      return { ...state, open: action.payload };

    case "SET_OPEN_TIER":
      return { ...state, open_tier: action.payload };

    case "SET_OPEN_JUR_TAG":
      return { ...state, open_jur_tag: action.payload };
    case "SUCCESS_TAGS":
      return { ...state, success: action.payload };
    case "CONFIRM_ALERTS_IMPORT":
      return {
        ...state,
        all: state.all.map((tag) =>
          tag.syntax_created === 0 && tag.tag_status_id === 1
            ? { ...tag, syntax_created: 1 }
            : tag
        )
      };
    case "FILTER_TAG":
      let result = state.all;

      Object.keys(action.payload).forEach((filter) => {
        switch (filter) {
          case "name":
          case "url":
            result = result.filter(
              (tag) =>
                tag[filter] &&
                tag[filter]
                  .toLowerCase()
                  .indexOf(action.payload[filter].toLowerCase()) !== -1
            );
          case "tag_type":
          case "tag_status":
          case "jurisdiction":
            if (action.payload[filter])
              result = result.filter(
                (tag) => tag[filter + "_id"] === action.payload[filter].id
              );
          case "languages":
            if (action.payload.languages) {
              console.log(action.payload.languages);
              action.payload.languages.forEach((lang) => {
                result = result.filter((tag) =>
                  tag.languages.find((l) => l.id === lang.id)
                );
              });
            }
        }
      });

      return {
        ...state,
        filter: { active: true, filters: action.payload, result }
      };
    case "ERROR_TAGS":
      let tmpError = action.payload;
      try {
        tmpError = JSON.parse(tmpError.response.data.message);
        tmpError.list = tmpError.list
          ? "<ul>" +
            tmpError.list.map((error) => "<li>" + error + "</li>").join("") +
            "</ul>"
          : "";
      } catch (err) {
        tmpError = {
          msg: tmpError.response
            ? tmpError.response.data.message
            : tmpError.toString(),
          list: ""
        };
      }

      return { ...state, error: tmpError };

    case "CLEAR_ERROR_TAGS":
      return { ...state, error: null };
    case "LOADING_TAGS":
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default tags;
