import React, { useEffect, useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import UsersList from "./components/UsersList";
import ErrorSnack from "../shared/components/UIElements/ErrorSnack";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../shared/hooks/http-hook";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";

import Auth from "./Auth";
import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";

import DuplicatePie from "../stats/DuplicatesPie";
import Parents from "../stats/Parents";

const Home = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();

  
  if(props.isLoggedIn) history.push("/inbox");

  return (
    <React.Fragment>
      <ErrorSnack error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && (
        <React.Fragment>
          <CssBaseline />
          <Container maxWidth="xl">
            {!props.isLoggedIn ? <Auth /> : <Fragment></Fragment>}
          </Container>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Home;
