import React, { Fragment, useState, useEffect } from "react";
import clsx from "clsx";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Autocomplete from "@material-ui/lab/Autocomplete";

import ChipInput from "material-ui-chip-input";

import {
  Icon,
  CircularProgress,
  Dialog,
  useMediaQuery,
  useTheme,
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  TextField,
  InputAdornment,
  Input,
  IconButton,
  InputLabel,
  OutlinedInput,
  Select,
  FormControl,
  MenuItem,
  ListItemText,
  Checkbox
} from "@material-ui/core";

import {
  getTiers,
  clearErrorTags,
  setCurrentTier,
  updateTier,
  clearCurrentTier,
  setOpenTier
} from "../actions/tags";

import { getLanguages } from "../actions/related";

const emptyTag = {
  tag: ""
};

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const TagForm = ({
  current,
  prefilled,
  clearPrefilled,
  open,
  clearCurrentTier,
  updateTier,
  setOpenTier,
  loading
}) => {
  const classes = useStyles();
  const [tier, setTier] = useState({});

  const { id, triggers } = tier;

  const handleChange = (chips) => {
    tier.triggers = chips.join(";");
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  useEffect(() => {
    if (current !== null) {
      setTier({ ...current });
    }
  }, [current, open, prefilled]);

  function handleClose() {
    setOpenTier(false);
    clearCurrentTier();
    if (clearPrefilled) clearPrefilled();
  }
  function onSubmit(e) {
    e.preventDefault();

    try {
      updateTier(tier);
    } catch (error) {
      console.log(error);
    }
  }

  const clearAll = () => {
    setTier({});
    clearCurrentTier();
  };

  const onChange = (e, newVal = null) => {
    let val = e.target.value;
    setTier({ ...tier, [e.target.name]: val });
  };

  return (
    <React.Fragment>
      <CssBaseline />

      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        fullScreen={true}
        style={
          !useMediaQuery(useTheme().breakpoints.down("sm"))
            ? { width: "90%", height: "90%", margin: "auto" }
            : {}
        }
        open={
          (current && !(!current.id && current.tag_status_id === 5)) || open
            ? true
            : false
        }
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        scroll="paper"
      >
        <DialogTitle
          id="max-width-dialog-title"
          color="primary"
          className={classes.formHeader}
        >
          {!current && open
            ? "New Tag"
            : current && !open
            ? "Edit Tier " + tier.id + " Triggers"
            : "Add Tag"}
        </DialogTitle>

        <DialogContent dividers={true}>
          <form onSubmit={onSubmit}>
            <input type="submit" style={{ display: "none" }} />
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={12}>
                <ChipInput
                  label={"Triggers"}
                  defaultValue={
                    tier.triggers
                      ? tier.triggers
                          .split(";")
                          .sort((a, b) => a.localeCompare(b))
                      : []
                  }
                  fullWidth
                  onChange={(chips) => handleChange(chips)}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className={classes.formFooter}>
          <Button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Save
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            disabled={loading}
          >
            Cancel
          </Button>
        </DialogActions>
        <Fragment>
          <CssBaseline />
        </Fragment>
      </Dialog>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650
  },
  progress: {
    position: "absolute",
    top: "60px",
    right: "20px"
  },
  modalPaper: {
    width: "10%"
  },

  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  formHeader: {
    backgroundColor: "#002060",
    color: "white"
  },
  formFooter: {
    backgroundColor: "gainsboro",
    padding: "10px"
  }
}));

const mapStateToProps = (state) => ({
  tiers: state.tags.tiers,
  loading: state.tags.loading,
  error: state.tags.error,
  current: state.tags.current_tier,
  open: state.tags.open_tier
});

const mapDispatchToProps = (dispatch) => ({
  getTiers: () => dispatch(getTiers()),
  clearErrorTags: () => dispatch(clearErrorTags()),
  setCurrentTier: (tag) => dispatch(setCurrentTier(tag)),
  setOpenTier: (should) => dispatch(setOpenTier(should)),
  clearCurrentTier: () => dispatch(clearCurrentTier()),
  updateTier: (tag) => dispatch(updateTier(tag))
});
export default connect(mapStateToProps, mapDispatchToProps)(TagForm);
