import { Button, Grid, Icon, IconButton, Snackbar } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import MuiAlert from "@material-ui/lab/Alert";
import { utc } from "moment-timezone";
import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { getBinArticles, reassignMother, setBackToInbox } from "../actions/articles";
import { getAlertSubaccounts, getJurisdictions, getLanguages, getSourceTypes } from "../actions/related";
import { getSources } from "../actions/sources";
import { getUsers } from "../actions/users";
import ErrorSnack from "../shared/components/UIElements/ErrorSnack";
import ProgressBar from "../shared/components/UIElements/ProgressBar";
import InboxArticle from "./InboxArticle";
import InboxCard from "./InboxCard";

const Dashboard = ({
  error,
  sources,
  alert_subaccounts,
  getSources,
  getAlertSubaccounts,
  getSourceTypes,
  source_types,
  getJurisdictions,
  jurisdictions,
  languages,
  getLanguages,
  getBinArticles,
  users,
  getUsers,
  bin_articles,
  show_articles,
  loading,
  setBackToInbox,
  reassignMother,
  filter
}) => {
  const [date, setDate] = useState(utc(new Date()).subtract(19, "days").toDate());

  const [open, setOpen] = React.useState(false);

  const [page, setPage] = React.useState(1);

  const [type, setType] = React.useState(3);

  const [reassign, setReassign] = React.useState(null);
  const [reassignChildren, setReassignChildren] = React.useState([]);

  const [selectedArticle, setSelectedArticle] = React.useState({});

  const [undoDeleteArticle, setUndoDeleteArticle] = React.useState(null);

  const [undoApproveArticle, setUndoApproveArticle] = React.useState(null);

  const [success, setSuccess] = React.useState("");

  const handleCloseReassigned = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess("");
  };

  const rowsPerPage = 100;

  const addReassignChildren = (child) => {
    setReassignChildren([...reassignChildren, child]);
  };

  const removeReassignChildren = (child) => {
    setReassignChildren(reassignChildren.filter((c) => c.id !== child.id));
  };

  useEffect(() => {
    if ((!sources || sources.length === 0) && !error) getSources();
    if (!source_types || !source_types.length) getSourceTypes();
    if (!jurisdictions || !jurisdictions.length) getJurisdictions();
    if (!languages || !languages.length) getLanguages();
    if (!users || !users.length) getUsers();
    if (!alert_subaccounts || alert_subaccounts.length === 0) getAlertSubaccounts();
  }, [date]);

  useEffect(() => {
    getBinArticles(type);
  }, [type]);

  const columns = [
    {
      id: "publisher",
      numeric: false,
      disablePadding: false,
      label: "Publisher"
    },
    {
      id: "original_header",
      numeric: false,
      disablePadding: false,
      label: "Header"
    },
    {
      id: "translated_header",
      numeric: false,
      disablePadding: false,
      label: "Translated Header"
    },
    {
      id: "original_url",
      numeric: false,
      disablePadding: false,
      label: "URL",
      noSelect: true,
      transform: function (row) {
        return (
          <a href={row.original_url} target="_blank">
            {row.original_url.slice(0, 30) + "..."}
          </a>
        );
      }
    },
    {
      id: "extracted_data",
      numeric: false,
      disablePadding: false,
      label: "Extract",
      noSelect: true,
      transform: function (row) {
        if (row.article_content && row.article_content.content)
          return <a onClick={(e) => handleClickOpen(row)}>EXTRACTED</a>;
      }
    },
    {
      id: "source_id",
      numeric: false,
      disablePadding: false,
      label: "Source",
      noSelect: true,
      transform: function (row) {
        if (!row.source_id) return "N/A";
        else return sources.find((s) => s.id === row.source_id).name;
      }
    }
  ];

  const handleClickOpen = (article) => {
    setSelectedArticle(article);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedArticle({});
  };
  const classes = useStyles();

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bin - ASPU</title>
      </Helmet>
      {loading && <ProgressBar />}
      <ErrorSnack error={error} />

      <InboxArticle selectedArticle={selectedArticle} open={open || false} onClose={handleClose} />

      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="xl" style={{ width: "90%" }}>
          {reassign && (
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              style={{ top: "64px" }}
              ContentProps={{
                classes: {
                  root: classes.snack
                }
              }}
              open={reassign !== null}
              onClose={handleClose}
              message={
                'Assigning children to "' +
                (reassign.source ? reassign.source.name + "'s\"" : "") +
                ' article "' +
                (reassign.translated_header || reassign.original_header).slice(0, 25) +
                '..."'
              }
              action={
                <React.Fragment>
                  {reassignChildren.length > 0 && (
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={(e) => {
                        const justArticle = {
                          ...reassign
                        };
                        delete justArticle.articles;
                        reassignMother(justArticle, reassignChildren);
                        setReassign(null);
                        setReassignChildren([]);
                        setSuccess("Children reassigned successfully");
                      }}
                    >
                      REASSIGN {reassignChildren.length} ARTICLES
                    </Button>
                  )}
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={(e) => {
                      setReassign(null);
                      setReassignChildren([]);
                    }}
                  >
                    <Icon fontSize="small">close</Icon>
                  </IconButton>
                </React.Fragment>
              }
            />
          )}
          <Snackbar
            open={!loading && success}
            autoHideDuration={3000}
            onClose={handleCloseReassigned}
            style={{ top: "64px" }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          >
            <MuiAlert elevation={6} variant="filled" onClose={handleCloseReassigned} severity="success">
              {success}
            </MuiAlert>
          </Snackbar>

          <br />
          <br />

          <div className={classes.marginAutoContainer}>
            <div className={classes.marginAutoItem}>
              <Button
                variant="contained"
                style={{
                  marginLeft: "10px",
                  marginTop: "2px",
                  textTransform: "unset",
                  float: "left",
                  backgroundColor: "rgb(253, 174, 97)",
                  ...(type === 3 && {
                    border: "2px solid black"
                  })
                }}
                color={true ? "primary" : ""}
                onClick={(e) => {
                  setType(3);
                }}
              >
                DELETED
              </Button>
              <Button
                variant="contained"
                style={{
                  marginLeft: "10px",
                  marginTop: "2px",
                  textTransform: "unset",
                  float: "left",
                  backgroundColor: "rgb(215, 25, 28)",
                  ...(type === 2 && {
                    border: "2px solid black"
                  })
                }}
                color={true ? "primary" : ""}
                onClick={(e) => {
                  setType(2);
                }}
              >
                EXCLUDED
              </Button>
              <br />
              <br />
              <br />

              <Pagination
                count={Math.ceil(show_articles.length / rowsPerPage)}
                page={page}
                onChange={(e, value) => setPage(value)}
                boundaryCount={20}
              />
            </div>
          </div>

          <Grid container>
            {show_articles.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((article, index) => (
              <Grid item md={12} padding={120} key={index}>
                <InboxCard
                  key={article.id}
                  article={article}
                  sources={sources}
                  inboxPosition={index}
                  users={users}
                  source_types={source_types}
                  jurisdictions={jurisdictions}
                  languages={languages}
                  setBackToInbox={setBackToInbox}
                  reassign={reassign}
                  setReassign={setReassign}
                  addReassignChildren={addReassignChildren}
                  reassignChildren={reassignChildren}
                  toReassign={reassignChildren.map((c) => c.id).indexOf(article.id) !== -1}
                  removeReassignChildren={removeReassignChildren}
                  setUndoDeleteArticle={setUndoDeleteArticle}
                  setUndoApproveArticle={setUndoApproveArticle}
                  setSuccess={setSuccess}
                />
              </Grid>
            ))}
          </Grid>
          <div className={classes.marginAutoContainer}>
            <div className={classes.marginAutoItem}>
              <Pagination
                count={Math.ceil(show_articles.length / rowsPerPage)}
                page={page}
                onChange={(e, value) => {
                  setPage(value);
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                  });
                }}
                boundaryCount={20}
              />
            </div>
          </div>
          {undoDeleteArticle && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              open={!loading && undoDeleteArticle !== null}
              autoHideDuration={6000}
              onClose={(e, reason) => {
                if (reason === "timeout") setUndoDeleteArticle(null);
              }}
              message={
                (undoDeleteArticle.source ? undoDeleteArticle.source.name + "'s \"" : '"') +
                (undoDeleteArticle.translated_header || undoDeleteArticle.original_header).slice(0, 25) +
                '..." ' +
                "article moved to bin"
              }
              action={
                <React.Fragment>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      setBackToInbox(undoDeleteArticle);
                      setUndoDeleteArticle(null);
                    }}
                  >
                    UNDO
                  </Button>
                  <Button style={{ color: "yellow" }} size="small" onClick={(e) => setUndoDeleteArticle(null)}>
                    DISABLE SOURCE
                  </Button>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={(e) => setUndoDeleteArticle(null)}
                  >
                    <Icon fontSize="small">close</Icon>
                  </IconButton>
                </React.Fragment>
              }
            />
          )}

          {undoApproveArticle && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              open={!loading && undoApproveArticle !== null}
              autoHideDuration={6000}
              onClose={(e, reason) => {
                if (reason === "timeout") setUndoApproveArticle(null);
              }}
              message={
                (undoApproveArticle.source ? undoApproveArticle.source.name + "'s \"" : '"') +
                (undoApproveArticle.translated_header || undoApproveArticle.original_header).slice(0, 25) +
                '..." ' +
                "article approved"
              }
              action={
                <React.Fragment>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      setBackToInbox(undoApproveArticle);
                      setUndoApproveArticle(null);
                    }}
                  >
                    UNDO
                  </Button>

                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={(e) => setUndoApproveArticle(null)}
                  >
                    <Icon fontSize="small">close</Icon>
                  </IconButton>
                </React.Fragment>
              }
            />
          )}
        </Container>
      </React.Fragment>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableHead: {
    backgroundColor: "#eee"
  },
  progress: {
    position: "absolute",
    top: "60px",
    right: "20px"
  },
  modalPaper: {
    width: "10%"
  },
  snack: { backgroundColor: "#878bff" },
  marginAutoContainer: {
    width: "100%",
    height: "auto",
    display: "flex",
    marginBottom: "20px",
    justifyContent: "flex-end"
  },
  marginAutoItem: {
    //margin: "auto"
  }
});

const mapStateToProps = (state) => ({
  sources: state.sources.all,
  alert_subaccounts: state.related.alert_subaccounts,
  source_types: state.related.source_types,
  jurisdictions: state.related.jurisdictions,
  languages: state.related.languages,
  dashboard_articles: state.articles.dashboard,
  filter: state.articles.filter,
  loading: state.articles.loading,
  show_articles: state.articles.bin,
  users: state.users.all
});

const mapDispatchToProps = (dispatch) => ({
  getSources: () => dispatch(getSources()),
  getAlertSubaccounts: () => dispatch(getAlertSubaccounts()),
  getSourceTypes: () => dispatch(getSourceTypes()),
  getJurisdictions: () => dispatch(getJurisdictions()),
  getLanguages: () => dispatch(getLanguages()),
  getBinArticles: (type) => dispatch(getBinArticles(type)),
  getUsers: () => dispatch(getUsers()),
  setBackToInbox: (article) => dispatch(setBackToInbox(article)),
  reassignMother: (mother, children) => dispatch(reassignMother(mother, children))
});
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
