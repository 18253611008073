const initialState = {
  all: [],
  current: null,
  filtered: null,
  filter: {
    active: false,
    filters: { source_status: { id: 1, name: "Active" } },
    result: []
  },
  error: null,
  loading: true,
  bypassIo: false,
  open: false,
  success: null
};

const sources = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_SOURCE":
      return { ...state, all: [...state.all, action.payload] };
    case "DELETE_SOURCE":
      return {
        ...state,
        all: state.all.filter((source) => source.id !== action.payload),
        filter: {
          ...state.filter,
          result: state.filter.result.filter(
            (source) => source.id !== action.payload
          )
        }
      };
    case "UPDATE_SOURCE":
      console.log(action.payload);
      return {
        ...state,
        all: state.all.map((source) =>
          source.id !== action.payload.id ? source : action.payload
        ),
        filter: {
          ...state.filter,
          result: state.filter.result.map((source) =>
            source.id !== action.payload.id ? source : action.payload
          )
        }
      };
    case "GET_SOURCES":
      return { ...state, all: action.payload };
    case "CURRENT_SOURCE":
      return { ...state, current: action.payload };
    case "CLEAR_CURRENT_SOURCE":
      return { ...state, current: null };
    case "SET_BYPASS_IO_SOURCES":
      return { ...state, bypassIo: action.payload };
    case "SET_OPEN_SOURCE":
      return { ...state, open: action.payload };
    case "SUCCESS_SOURCES":
      return { ...state, success: action.payload };
    case "CONFIRM_ALERTS_IMPORT":
      return {
        ...state,
        all: state.all.map((source) =>
          source.syntax_created === 0 && source.source_status_id === 1
            ? { ...source, syntax_created: 1 }
            : source
        ),
        filter: {
          ...state.filter,
          result: state.filter.result.map((source) =>
            source.syntax_created === 0 && source.source_status_id === 1
              ? { ...source, syntax_created: 1 }
              : source
          )
        }
      };
    case "FILTER_SOURCE":
      let result = state.all;

      Object.keys(action.payload).forEach((filter) => {
        switch (filter) {
          case "name":
          case "url":
            result = result.filter(
              (source) =>
                source[filter] &&
                source[filter]
                  .toLowerCase()
                  .indexOf(action.payload[filter].toLowerCase()) !== -1
            );
          case "source_type":
          case "source_status":
          case "jurisdiction":
            if (action.payload[filter])
              result = result.filter(
                (source) => source[filter + "_id"] === action.payload[filter].id
              );
          case "languages":
            if (action.payload.languages) {
              console.log(action.payload.languages);
              action.payload.languages.forEach((lang) => {
                result = result.filter((source) =>
                  source.languages.find((l) => l.id === lang.id)
                );
              });
            }
        }
      });

      return {
        ...state,
        filter: { active: true, filters: action.payload, result }
      };
    case "ERROR_SOURCES":
      let tmpError = action.payload;
      try {
        tmpError = JSON.parse(tmpError.response.data.message);
        tmpError.list = tmpError.list
          ? "<ul>" +
            tmpError.list.map((error) => "<li>" + error + "</li>").join("") +
            "</ul>"
          : "";
      } catch (err) {
        tmpError = {
          msg: tmpError.response
            ? tmpError.response.data.message
            : tmpError.toString(),
          list: ""
        };
      }

      return { ...state, error: tmpError };

    case "CLEAR_ERROR_SOURCES":
      return { ...state, error: null };
    case "LOADING_SOURCES":
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default sources;
