import React, { useEffect, useState, useContext, Fragment } from "react";
import { connect } from "react-redux";

import {
  deleteTag,
  getTags,
  clearErrorTags,
  setCurrentTag,
  clearCurrentTag,
  setBypassIoTags
} from "../actions/tags";
import { getLanguages } from "../actions/related";

import subscribeTo from "../utils/subscribeTo";
import { AuthContext } from "../shared/context/auth-context";
import TagForm from "./TagForm";
import AddTagBtn from "./AddTagBtn";
import ErrorSnack from "../shared/components/UIElements/ErrorSnack";
import EnhancedTable from "../shared/components/UIElements/EnhancedTable";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  Icon,
  Breadcrumbs,
  Link,
  TextField,
  Grid,
  Button
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useConfirm } from "material-ui-confirm";
import ProgressBar from "../shared/components/UIElements/ProgressBar";
import Axios from "axios";
import { Helmet } from "react-helmet";

const TagManager = ({
  tags,
  loading,
  error,
  current,
  bypassIo,
  clearErrorTags,
  setCurrentTag,
  clearCurrentTag,
  setBypassIoTags,
  deleteTag,
  getTags,
  dispatch,
  allLanguages,
  getLanguages,
  tag_types,
  getTagTypes,
  filter
}) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const confirm = useConfirm();
  function validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }
  const lang_triggers = ["en", "zh", "fr", "pt", "es", "ar", "ms", "it"];

  const columns = [
    {
      id: "tag",
      numeric: false,
      disablePadding: false,
      width: "15%",
      label: "Tag"
    },
    {
      id: "en_triggers",
      numeric: false,
      disablePadding: false,
      label: "Triggers",
      noSelect: true,
      transform: function (row) {
        let triggers = [];
        lang_triggers.forEach((lang) => {
          if (row[lang + "_triggers"]) {
            if (triggers.length) triggers.push(<br />);
            triggers.push(
              <strong>{allLanguages.find((l) => l.id === lang).name}: </strong>
            );
            triggers.push(
              <span>{row[lang + "_triggers"].replace(/;/g, " • ")}</span>
            );
          }
        });
        return triggers;
      }
    }
  ];

  useEffect(() => {
    if (!allLanguages || !allLanguages.length) getLanguages();

    if ((!tags || tags.length === 0 || !tags[0].en_triggers) && !error)
      getTags();

    if (!loading) {
      subscribeTo("tagsChanged", (err, data) => {
        if (!bypassIo) getTags();
        else {
          getTags();
          setBypassIoTags(false);
        }
      });
      return () => {
        subscribeTo("tagsChanged", null);
      };
    }
  }, [loading, bypassIo]);

  const onClickEdit = (tag) => {
    setCurrentTag(tag);
  };

  const onClickDelete = (tag) => {
    confirm({
      description: `Are you sure you want to delete the tag "${tag.tag}"? This will delete this tag and remove it from all entries.`,
      title: "WARNING",
      cancellationText: "Cancel",
      confirmationText: "Confirm"
    }).then(() => deleteTag(tag.id));
  };

  const onClickRerun = async (e) => {
    const fetchData = async () => {
      const result = await Axios(
        process.env.REACT_APP_BACKEND_URL +
          "/background_jobs/rerunAssignTagsInbox"
      );

      console.log(result.data);
    };

    fetchData();
  };

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tag Manager - ASPU</title>
      </Helmet>
      {loading && <ProgressBar />}
      <ErrorSnack error={error} clearError={clearErrorTags} />
      <React.Fragment>
        <CssBaseline />
        <TagForm
          prefilled={false}
          current={current}
          clearCurrentTag={clearCurrentTag}
        />
        <Container maxWidth="xl">
          {tags.length !== 0 && allLanguages.length !== 0 && (
            <Fragment>
              <Button onClick={(e) => onClickRerun(e)}>RERUN</Button>
              <EnhancedTable
                rows={filter.active ? filter.result : tags}
                columns={columns}
                options={{
                  title: "Tag Manager",
                  multiple_select: false,
                  hide_pagination: true
                }}
                actions={{ edit: onClickEdit, delete: onClickDelete }}
              ></EnhancedTable>
            </Fragment>
          )}
          <AddTagBtn />
        </Container>
      </React.Fragment>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableHead: {
    backgroundColor: "#eee"
  },
  progress: {
    position: "absolute",
    top: "60px",
    right: "20px"
  },
  modalPaper: {
    width: "10%"
  }
});

const mapStateToProps = (state) => ({
  tags: state.tags.all,
  loading: state.tags.loading,
  error: state.tags.error,
  current: state.tags.current,
  filter: state.tags.filter,
  bypassIo: state.tags.bypassIo,
  allLanguages: state.related.languages,
  tag_types: state.related.tag_types,

  jurisdictions: state.related.jurisdictions
});

const mapDispatchToProps = (dispatch) => ({
  deleteTag: (id) => dispatch(deleteTag(id)),
  getTags: () => dispatch(getTags()),
  clearErrorTags: () => dispatch(clearErrorTags()),
  setCurrentTag: (tag) => dispatch(setCurrentTag(tag)),
  clearCurrentTag: () => dispatch(clearCurrentTag()),
  setBypassIoTags: (bypass) => dispatch(setBypassIoTags(bypass)),
  getLanguages: () => dispatch(getLanguages())
});
export default connect(mapStateToProps, mapDispatchToProps)(TagManager);
