const initialState = {
  all: [],
  current: null,
  filtered: null,
  filter: null,
  error: null,
  loading: true,
  bypassIo: false,
  open: false,
  user_languages: [],
  logged_user: {}
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_USER":
      return { ...state, all: [...state.all, action.payload] };
    case "DELETE_USER":
      return {
        ...state,
        all: state.all.filter((user) => user.id !== action.payload)
      };
    case "UPDATE_USER":
      return {
        ...state,
        all: state.all.map((user) =>
          user.id !== action.payload.id ? user : action.payload
        )
      };
    case "GET_USERS":
      return { ...state, all: action.payload };
    case "GET_USER_LANGUAGES":
      return { ...state, user_languages: action.payload };
    case "GET_USER_DETAILS":
      return { ...state, logged_user: action.payload };
    case "CURRENT_USER":
      return { ...state, current: action.payload };
    case "CLEAR_CURRENT_USER":
      return { ...state, current: null };
    case "SET_BYPASS_IO_USERS":
      return { ...state, bypassIo: action.payload };
    case "SET_OPEN_USER":
      return { ...state, open: action.payload };
    case "ADD_CONFIRMED":
      if (!state.logged_user || !state.logged_user.confirmed) return state;
      else
        return {
          ...state,
          logged_user: {
            ...state.logged_user,
            confirmed: state.logged_user.confirmed + 1
          }
        };
    case "ADD_REJECTED":
      if (!state.logged_user || !state.logged_user.rejected) return state;
      else
        return {
          ...state,
          logged_user: {
            ...state.logged_user,
            rejected: state.logged_user.rejected + 1
          }
        };
    case "ERROR_USERS":
      let tmpError = action.payload;
      try {
        tmpError = JSON.parse(tmpError.response.data.message);
        tmpError.list = tmpError.list
          ? "<ul>" +
            tmpError.list.map((error) => "<li>" + error + "</li>").join("") +
            "</ul>"
          : "";
      } catch (err) {
        tmpError = {
          msg: tmpError.response
            ? tmpError.response.data.message
            : tmpError.toString(),
          list: ""
        };
      }

      return { ...state, error: tmpError };

    case "CLEAR_ERROR_USERS":
      return { ...state, error: null };
    case "LOADING_USERS":
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default users;
